// Libraries
import React from 'react';

import {UXCamHandleIdentifyArgs, UXCamHandleTrackArgs, UXCamProps} from './UXCamTypes';

class UXCam extends React.Component<UXCamProps> {
  get isEnabled() {
    return !!this.props.apiKey;
  }

  componentDidMount() {
    if (this.isEnabled) {
      // TODO
    }
  }

  handleIdentify = ({id, firstName, lastName, email, ...rest}: UXCamHandleIdentifyArgs) => {
    if (this.isEnabled && id) {
      // TODO
    }
  };

  handleTrack = ({event, data}: UXCamHandleTrackArgs) => {
    if (this.isEnabled) {
      // TODO
    }
  };

  handleReset = () => {
    if (this.isEnabled) {
      // TODO
    }
  };

  render() {
    return this.props.children({
      identify: this.handleIdentify,
      track: this.handleTrack,
      reset: this.handleReset,
    });
  }
}

export default UXCam;
