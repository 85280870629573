import React from 'react';

const CodePush = (options: unknown) => {
  return (component: React.ReactNode) => {
    return component;
  };
};

const NO_UPDATE_RESPONSE = null;

// eslint-disable-next-line
const UPDATE_RESPONSE = {};

// Enums
CodePush.CheckFrequency = {
  MANUAL: 'MANUAL',
};

CodePush.InstallMode = {
  IMMEDIATE: 'IMMEDIATE',
};

CodePush.SyncStatus = {
  UP_TO_DATE: 0,
  UPDATE_INSTALLED: 1,
  UPDATE_IGNORED: 2,
  UNKNOWN_ERROR: 3,
  SYNC_IN_PROGRESS: 4,
  CHECKING_FOR_UPDATE: 5,
  AWAITING_USER_ACTION: 6,
  DOWNLOADING_PACKAGE: 7,
  INSTALLING_UPDATE: 8,
};

// Methods
CodePush.checkForUpdate = async () => {
  return new Promise<boolean | null>((resolve, reject) => {
    setTimeout(() => resolve(NO_UPDATE_RESPONSE), 50);
  });
};

CodePush.sync = async (options: unknown, onStatusChange: (newStatus: number) => void) => {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => {
      resolve();

      // After successful install, the native CodePush module restarts the app. We will simulate
      // this by redirecting to the root url.
      window.location.href = '/';
    }, 5000);
  });
};

export default CodePush;
