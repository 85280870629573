// Libraries
import {
  DocumentNode,
  InMemoryCache as InMemoryCacheTyped,
  Operation as OperationTyped,
} from '@apollo/client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {Resolvers} from 'apollo-client';
import {Operation} from 'apollo-link';
import _ from 'lodash';

// Supermove
import {
  createAuthenticationMiddleware,
  createAuthenticationMiddlewareTyped,
  createCache,
  createCacheTyped,
  createClient,
  createClientTyped,
  createErrorMiddleware,
  createErrorMiddlewareTyped,
  createGraphQLMiddleware,
  createGraphQLMiddlewareTyped,
  createHeadersMiddleware,
  createHeadersMiddlewareTyped,
  createRetryMiddleware,
  createRetryMiddlewareTyped,
} from '@supermove/graphql';

// Modules
import Config from '../Config';
import Environment from '../Environment';
import Storage from '../Storage';

import onGraphQLError from './onGraphQLError';
import onGraphQLErrorTyped from './onGraphQLErrorTyped';

interface AppInfo {
  getBuildNumber: () => string;
  getVersion: () => string;
  name: string;
  platform: string;
}

const getOperationType = (operation: Operation) => {
  // TODO(jay): Proper type is wrapped in a library that is not currently exposed in supermove
  const definitions: any[] = _.get(operation, 'query.definitions', []);
  const operationDefinition = definitions.find(
    (definition) => definition.kind === 'OperationDefinition',
  );
  if (operationDefinition) {
    return operationDefinition.operation;
  }
};

const getTypedOperationType = (operation: OperationTyped) => {
  const definitions: DocumentNode['definitions'] = _.get(operation, 'query.definitions', []);
  const operationDefinition = definitions.find(
    (definition) => definition.kind === 'OperationDefinition',
  );
  if (operationDefinition && 'operation' in operationDefinition) {
    return operationDefinition.operation;
  }
};

const GraphQL = {
  createCache: () => {
    return createCache();
  },
  createCacheTyped: () => {
    return createCacheTyped();
  },

  createAppSyncClient: ({
    cache,
    resolvers,
  }: {
    cache: InMemoryCache;
    resolvers: Resolvers | Resolvers[];
  }) => {
    return createClient({
      name: 'AppSync',
      connectToDevTools: Environment.isLocal(),
      cache,
      resolvers,
      middleware: [
        createErrorMiddleware({onError: onGraphQLError}),
        createHeadersMiddleware({
          getHeaders: () => {
            return {
              'x-api-key': process.env.APPSYNC_API_KEY,
              'Content-Type': 'application/graphql',
            };
          },
        }),
        // GraphQL middleware must go last after we have authenticated.
        createGraphQLMiddleware({uri: `${Config.getAppSyncAPIHost()}/graphql`}),
      ],
    });
  },

  createClient: ({
    cache,
    resolvers,
    appInfo,
  }: {
    cache: InMemoryCache;
    resolvers: Resolvers | Resolvers[];
    appInfo: AppInfo;
  }) => {
    // Create the graphql client with authentication support.
    return createClient({
      connectToDevTools: Environment.isLocal(),
      cache,
      resolvers,
      middleware: [
        createRetryMiddleware({
          delay: {
            initial: 300,
            max: Infinity,
            jitter: true,
          },
          attempts: {
            max: Environment.isLocal() ? 0 : 3,
            retryIf: (error, operation) => {
              const isQuery = getOperationType(operation) !== 'mutation';
              return Boolean(error) && isQuery;
            },
          },
        }),
        // First, catch any errors and log them.
        createErrorMiddleware({onError: onGraphQLError}),

        createHeadersMiddleware({
          getHeaders: async () => {
            const isExperimental = await Storage.getItem('X_API_EXPERIMENTAL');
            const amplitudeSessionId = await Storage.getItem('amplitude_session_id');
            // 'location' is undefined on react-native
            const clientUrl = window.location
              ? {
                  'x-supermove-client-url': `${window.location.origin}${window.location.pathname}`,
                }
              : {};

            return {
              'x-api-experimental': isExperimental === 'true',
              'x-amplitude-session-id': amplitudeSessionId,
              'x-supermove-app-name': appInfo.name,
              'x-supermove-app-version': appInfo.getVersion(),
              'x-supermove-app-build': appInfo.getBuildNumber(),
              'x-supermove-app-platform': appInfo.platform,
              ...clientUrl,
            };
          },
        }),
        // If we're able to connect, then authenticate with our token.
        createAuthenticationMiddleware({
          getToken: async () => {
            const token = await Storage.getItem('token');
            return token ? `Bearer ${token}` : null;
          },
        }),

        // GraphQL middleware must go last after we have authenticated.
        createGraphQLMiddleware({uri: `${Config.getAPIHost()}/graphql`}),
      ],
      defaultOptions: {
        query: {
          // @ts-expect-error library says this isn't an option, but it definitely is--likely will go away on upgrade
          fetchPolicy: 'cache-and-network',
        },
      },
    });
  },
  onGraphQLError,

  createClientTyped: ({
    cache,
    resolvers,
    appInfo,
  }: {
    cache: InMemoryCacheTyped;
    resolvers: Resolvers | Resolvers[];
    appInfo: AppInfo;
  }) => {
    // Create the graphql client with authentication support.
    return createClientTyped({
      connectToDevTools: Environment.isLocal(),
      cache,
      resolvers,
      middleware: [
        createRetryMiddlewareTyped({
          delay: {
            initial: 300,
            max: Infinity,
            jitter: true,
          },
          attempts: {
            max: Environment.isLocal() ? 0 : 3,
            retryIf: (error, operation) => {
              const isQuery = getTypedOperationType(operation) !== 'mutation';
              return Boolean(error) && isQuery;
            },
          },
        }),
        // First, catch any errors and log them.
        createErrorMiddlewareTyped({onError: onGraphQLErrorTyped}),

        createHeadersMiddlewareTyped({
          getHeaders: async () => {
            const isExperimental = await Storage.getItem('X_API_EXPERIMENTAL');
            const amplitudeSessionId = await Storage.getItem('amplitude_session_id');
            // 'location' is undefined on react-native
            const clientUrl = window.location
              ? {
                  'x-supermove-client-url': `${window.location.origin}${window.location.pathname}`,
                }
              : {};

            return {
              'x-api-experimental': isExperimental === 'true',
              'x-amplitude-session-id': amplitudeSessionId,
              'x-supermove-app-name': appInfo.name,
              'x-supermove-app-version': appInfo.getVersion(),
              'x-supermove-app-build': appInfo.getBuildNumber(),
              'x-supermove-app-platform': appInfo.platform,
              ...clientUrl,
            };
          },
        }),
        // If we're able to connect, then authenticate with our token.
        createAuthenticationMiddlewareTyped({
          getToken: async () => {
            const token = await Storage.getItem('token');
            return token ? `Bearer ${token}` : null;
          },
        }),

        // GraphQL middleware must go last after we have authenticated.
        createGraphQLMiddlewareTyped({uri: `${Config.getAPIHost()}/graphql`}),
      ],
      defaultOptions: {
        query: {
          fetchPolicy: 'cache-first',
        },
      },
    });
  },
};

export default GraphQL;
