import gqlOriginal from 'graphql-tag';

// We are calling gql.errors and gql.query in several places, and these are not defined in the original ts definition.

type GQLType = typeof gqlOriginal & {
  query: string;
  errors: string;
};

const gql: GQLType = gqlOriginal as GQLType;

export default gql;
