const Device = {
  openUpdateApp: () => {
    // @ts-expect-error TS says reload doesn't take args, should test
    window.location.reload(true);
  },

  openSettings: () => {
    console.log('No settings on web');
  },

  restartApp: () => {
    // @ts-expect-error TS says reload doesn't take args, should test
    window.location.reload(true);
  },
};

export default Device;
