// Libraries
import _ from 'lodash';
import React from 'react';
import Helmet from 'react-helmet';

import {useState} from '@supermove/hooks';

// Navigation
import HeadContext from '../context/HeadContext';

import {HeadProps} from './HeadType';

const Head = ({initial, transform, children}: HeadProps) => {
  const [head, setHead] = useState(initial);
  const props = _.merge({}, initial, head);

  return (
    <HeadContext.Provider value={setHead}>
      <Helmet {...transform(props)} />
      {children}
    </HeadContext.Provider>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
Head.propTypes = {};

Head.defaultProps = {};

export default Head;
