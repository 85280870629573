// Libraries
import {createGlobalStyle} from 'styled-components';

const PrintCardStyle = createGlobalStyle`
  /* stylelint-disable unit-allowed-list */

  @media print {
    @page {
      size: letter;
      margin: 0in;
    }
  }
`;

export default PrintCardStyle;
