// Libraries
import React from 'react';
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch';

interface ZoomViewProps {
  defaultScale: number;
  defaultPositionX: number;
  defaultPositionY: number;
  minScale: number;
  centerContent: boolean;
  wrapperClass: string;
  children: React.ReactNode;
}

const ZoomView = ({
  defaultScale,
  defaultPositionX,
  defaultPositionY,
  minScale,
  centerContent,
  wrapperClass,
  children,
}: ZoomViewProps) => {
  return (
    <TransformWrapper
      defaultScale={defaultScale}
      defaultPositionX={defaultPositionX}
      defaultPositionY={defaultPositionY}
      // @ts-expect-error TS says wrapperClass doesn't exist
      options={{minScale, centerContent, limitToWrapper: true, wrapperClass}}
      wheel={{wheelEnabled: false}}
    >
      <TransformComponent>{children}</TransformComponent>
    </TransformWrapper>
  );
};

export default ZoomView;
