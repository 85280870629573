// Libraries
import {createGlobalStyle} from 'styled-components';

// Styles
import {focusStyle, hoverStyle, hoverDisabledStyle, hoverFocusStyle} from './utils';

// Hover style must be above focus style because focus has higher priority.
const GlobalStyle = createGlobalStyle`
  input, textarea {
    :hover {
      ${hoverStyle}
    }

    :focus {
      ${focusStyle}
    }

    :hover:disabled {
      ${hoverDisabledStyle}
    }

    :hover:focus {
      ${hoverFocusStyle}
    }

  }
`;

export default GlobalStyle;
