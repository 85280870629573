// Libraries
import React from 'react';
// @ts-expect-error library is not typed
import ReactPhoneInput from 'react-phone-number-input/basic-input';

// Supermove
import {Form} from '@supermove/hooks';
import {createStyledDOMInput} from '@supermove/styles';

const StyledPhoneInput = createStyledDOMInput(ReactPhoneInput);

interface PhoneInputProps {
  disabled?: boolean;
  required?: boolean;
  name: string;
  placeholder?: string;
  value: string;
  setFieldValue: Form['setFieldValue'];
  autoFocus?: boolean;
  style?: object;
}

const PhoneInput = ({
  disabled,
  required,
  name,
  placeholder,
  value,
  setFieldValue,
  autoFocus,
  style,
}: PhoneInputProps) => (
  <StyledPhoneInput
    country={'US'}
    disabled={disabled}
    required={required}
    placeholder={placeholder}
    value={value}
    onChange={(value: string) => setFieldValue(name, value)}
    style={style}
    autoFocus={autoFocus}
  />
);

export default PhoneInput;
