// Libraries
import React from 'react';

// Supermove
import {colors} from '@supermove/styles';

interface SupermoveLogoProps {
  color?: string;
  size?: number;
}

// Just the S
const SupermoveLogo = ({color, size}: SupermoveLogoProps) => {
  return (
    <svg
      width={size ? `${size}` : undefined}
      height={size ? `${size}` : undefined}
      viewBox='0 0 48 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill={color}
        d='M26.4192 23.3985L47.3588 2.45882C48.2313 1.50702 47.5968 0 46.3277 0H25.5467C24.4362 0 23.3258 0.475901 22.5326 1.26907L0.403223 23.4778C-0.469263 24.3503 0.165274 25.9366 1.43434 25.9366H20.5497C21.8188 25.9366 22.4533 27.4436 21.5808 28.3954L0.561858 49.2558C-0.310628 50.1283 0.323908 51.7146 1.59298 51.7146H22.4533C23.5638 51.7146 24.6742 51.2387 25.4674 50.4455L47.5968 28.2368C48.4693 27.3643 47.8347 25.778 46.5657 25.778H27.4503C26.1812 25.8573 25.5467 24.271 26.4192 23.3985Z'
      />
    </svg>
  );
};

interface SupermoveIconProps {
  color?: string;
  backgroundColor?: string;
  size?: number;
}

// The S in an icon square
SupermoveLogo.Icon = ({color, backgroundColor, size}: SupermoveIconProps) => {
  return (
    <svg
      width={size ? `${size}` : undefined}
      height={size ? `${size}` : undefined}
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect fill={backgroundColor} width={'100'} height={'100'} />
      <path
        fill={color}
        d='M52.4192 47.3985L73.3588 26.4588C74.2313 25.507 73.5968 24 72.3277 24H51.5467C50.4362 24 49.3258 24.4759 48.5326 25.2691L26.4032 47.4778C25.5307 48.3503 26.1653 49.9366 27.4343 49.9366H46.5497C47.8188 49.9366 48.4533 51.4436 47.5808 52.3954L26.5619 73.2558C25.6894 74.1283 26.3239 75.7146 27.593 75.7146H48.4533C49.5638 75.7146 50.6742 75.2387 51.4674 74.4455L73.5968 52.2368C74.4693 51.3643 73.8347 49.778 72.5657 49.778H53.4503C52.1812 49.8573 51.5467 48.271 52.4192 47.3985Z'
      />
    </svg>
  );
};

const LOGO_ASPECT_RATIO = 238 / 35;

interface SupermoveNameProps {
  color?: string;
  size?: number;
}

// The S followed by 'supermove'
SupermoveLogo.Name = ({color, size}: SupermoveNameProps) => {
  return (
    <svg
      width={size ? `${size * LOGO_ASPECT_RATIO}` : undefined}
      height={size ? `${size}` : undefined}
      viewBox='0 0 238 35'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill={color}
        d='M17.4007 15.4484L31.2399 1.60921C31.8031 1.04599 31.4007 0 30.5961 0H16.8373C16.1132 0 15.389 0.321842 14.8258 0.804606L0.26254 15.4484C-0.300684 16.0117 0.101658 17.0576 0.906264 17.0576H13.5385C14.3431 17.0576 14.8259 18.1036 14.1823 18.6669L0.423422 32.5061C-0.139802 33.0693 0.26254 34.1153 1.06715 34.1153H14.8258C15.5499 34.1153 16.2741 33.7934 16.8373 33.3107L31.4007 18.6669C31.964 18.1036 31.5616 17.0576 30.757 17.0576H18.1247C17.2397 17.0576 16.8375 16.0117 17.4007 15.4484Z'
      />
      <path
        fill={color}
        d='M47.7344 26.7935C46.4471 26.2303 45.401 25.4256 44.6769 24.3797C43.9527 23.3337 43.5504 22.2072 43.47 21.0003H47.8953C47.9758 21.8854 48.3781 22.6095 49.1828 23.1728C49.9069 23.736 50.8723 24.0578 52.0792 24.0578C53.2861 24.0578 54.1712 23.8164 54.8953 23.3337C55.539 22.8509 55.8608 22.2877 55.8608 21.5635C55.8608 20.7589 55.4586 20.1957 54.7345 19.8739C54.0103 19.4716 52.8034 19.0693 51.2746 18.667C49.7459 18.2647 48.4585 17.8624 47.493 17.4601C46.5275 17.0577 45.6423 16.4141 44.9986 15.6095C44.2745 14.8049 43.9528 13.6784 43.9528 12.391C43.9528 11.2646 44.2746 10.2991 44.9183 9.41399C45.562 8.52893 46.5275 7.80478 47.7344 7.24156C48.9413 6.67833 50.3091 6.43695 51.9183 6.43695C54.2517 6.43695 56.1827 7.00017 57.5506 8.20708C58.9989 9.41399 59.7229 11.0232 59.8839 13.0347H55.6196C55.5391 12.1497 55.1367 11.345 54.493 10.8623C53.8493 10.2991 52.8839 10.0577 51.7574 10.0577C50.631 10.0577 49.746 10.2991 49.1828 10.7014C48.6195 11.1037 48.2976 11.6669 48.2976 12.391C48.2976 12.9543 48.4585 13.437 48.8608 13.7589C49.2631 14.1612 49.7459 14.4025 50.3091 14.6439C50.8723 14.8853 51.7574 15.1267 52.8838 15.4485C54.4126 15.8508 55.6196 16.2531 56.5851 16.6554C57.5506 17.0577 58.3551 17.7014 59.0792 18.506C59.8034 19.3106 60.1253 20.3566 60.1253 21.7245C60.1253 22.9314 59.8035 23.9774 59.1598 24.8624C58.5161 25.7475 57.6311 26.4716 56.4242 27.0349C55.2173 27.5981 53.8493 27.8395 52.2401 27.8395C50.47 27.6785 49.0218 27.3567 47.7344 26.7935Z'
      />
      <path
        fill={color}
        d='M80.9646 6.75873V27.3566H76.7002V24.9428C76.0565 25.8279 75.1714 26.4716 74.0449 26.9543C72.999 27.4371 71.792 27.6785 70.5851 27.6785C68.9759 27.6785 67.5277 27.3566 66.2403 26.6325C64.9529 25.9888 63.9069 24.9428 63.1828 23.6554C62.4586 22.3681 62.0564 20.7589 62.0564 18.8278V6.75873H66.3207V18.2646C66.3207 20.1152 66.8036 21.5635 67.6886 22.529C68.5737 23.4945 69.861 23.9773 71.4702 23.9773C73.0795 23.9773 74.3668 23.4945 75.2519 22.529C76.2174 21.5635 76.6196 20.1152 76.6196 18.2646V6.75873H80.9646Z'
      />
      <path
        fill={color}
        d='M90.298 7.32197C91.5049 6.67829 92.9532 6.35645 94.5624 6.35645C96.3326 6.35645 98.0223 6.83921 99.4706 7.64381C100.919 8.52888 102.126 9.73579 102.93 11.345C103.815 12.9542 104.218 14.8048 104.218 16.8163C104.218 18.9083 103.815 20.7589 102.93 22.3681C102.045 23.9773 100.919 25.2647 99.4706 26.1497C98.0223 27.0348 96.3326 27.5176 94.5624 27.5176C92.9532 27.5176 91.5854 27.1957 90.3785 26.5521C89.1716 25.9084 88.1257 25.1038 87.4015 24.1382V33.9544H83.1371V6.75875H87.4015V9.81625C88.0452 8.77026 89.0911 7.96566 90.298 7.32197ZM98.9878 13.2761C98.4246 12.2301 97.62 11.4255 96.6545 10.9427C95.689 10.3795 94.6429 10.1381 93.597 10.1381C92.551 10.1381 91.5049 10.3795 90.5394 10.9427C89.5739 11.5059 88.7694 12.3105 88.2061 13.3565C87.6429 14.4025 87.321 15.6094 87.321 17.0577C87.321 18.506 87.6429 19.7129 88.2061 20.7589C88.7694 21.8049 89.5739 22.6095 90.5394 23.1727C91.5049 23.7359 92.551 23.9773 93.597 23.9773C94.7234 23.9773 95.689 23.7359 96.6545 23.1727C97.62 22.6095 98.4246 21.8049 98.9878 20.7589C99.551 19.7129 99.8729 18.4255 99.8729 17.0577C99.8729 15.529 99.6315 14.322 98.9878 13.2761Z'
      />
      <path
        fill={color}
        d='M126.505 18.5864H110.735C110.896 20.1956 111.459 21.5635 112.585 22.529C113.712 23.4945 114.999 24.0577 116.608 24.0577C118.942 24.0577 120.551 23.0922 121.436 21.1612H126.023C125.379 23.0922 124.252 24.621 122.643 25.8279C121.034 27.0348 119.022 27.6785 116.528 27.6785C114.597 27.6785 112.827 27.2762 111.218 26.3911C109.689 25.506 108.402 24.2991 107.517 22.6899C106.632 21.0807 106.229 19.2301 106.229 17.1381C106.229 15.0462 106.632 13.1151 107.517 11.5059C108.402 9.89668 109.609 8.68977 111.137 7.8047C112.666 6.91964 114.517 6.51733 116.528 6.51733C118.459 6.51733 120.229 6.91964 121.758 7.8047C123.287 8.68977 124.494 9.81622 125.298 11.345C126.183 12.8737 126.586 14.6439 126.586 16.6554C126.666 17.2991 126.586 18.0232 126.505 18.5864ZM122.241 15.2071C122.241 13.5979 121.678 12.391 120.551 11.4254C119.425 10.4599 118.057 9.97714 116.448 9.97714C114.999 9.97714 113.712 10.4599 112.666 11.345C111.62 12.3105 110.976 13.5174 110.815 15.1266H122.241V15.2071Z'
      />
      <path
        fill={color}
        d='M135.517 7.24151C136.563 6.67829 137.77 6.35645 139.218 6.35645V10.7818H138.172C136.482 10.7818 135.275 11.1841 134.39 12.0691C133.505 12.9542 133.103 14.4025 133.103 16.4945V27.3567H128.839V6.75875H133.103V9.73579C133.666 8.6898 134.471 7.8852 135.517 7.24151Z'
      />
      <path
        fill={color}
        d='M170.678 7.40243C171.966 8.04612 173.011 9.0921 173.736 10.3795C174.46 11.6668 174.862 13.2761 174.862 15.1266V27.3567H170.598V15.7703C170.598 13.9197 170.115 12.4714 169.23 11.5059C168.345 10.5404 167.057 10.0576 165.448 10.0576C163.839 10.0576 162.552 10.5404 161.666 11.5059C160.701 12.4714 160.299 13.9197 160.299 15.7703V27.3567H156.034V15.7703C156.034 13.9197 155.551 12.4714 154.666 11.5059C153.781 10.5404 152.494 10.0576 150.885 10.0576C149.276 10.0576 147.988 10.5404 147.103 11.5059C146.138 12.4714 145.735 13.9197 145.735 15.7703V27.3567H141.471V6.75875H145.735V9.09211C146.46 8.20704 147.344 7.56335 148.39 7.08059C149.436 6.59783 150.643 6.35645 151.85 6.35645C153.459 6.35645 154.988 6.67829 156.276 7.40243C157.563 8.12658 158.609 9.0921 159.253 10.4599C159.896 9.25303 160.862 8.20704 162.149 7.48289C163.437 6.75875 164.885 6.35645 166.414 6.35645C167.942 6.35645 169.391 6.75875 170.678 7.40243Z'
      />
      <path
        fill={color}
        d='M181.943 26.3106C180.334 25.4256 179.127 24.2187 178.241 22.6094C177.356 21.0002 176.874 19.1496 176.874 17.0577C176.874 14.9657 177.356 13.1151 178.241 11.5059C179.127 9.89668 180.414 8.60931 182.023 7.8047C183.632 6.91964 185.402 6.51733 187.334 6.51733C189.265 6.51733 191.115 6.91964 192.644 7.8047C194.253 8.68977 195.46 9.89668 196.426 11.5059C197.311 13.1151 197.793 14.9657 197.793 17.0577C197.793 19.1496 197.311 21.0002 196.345 22.6094C195.38 24.2187 194.092 25.506 192.483 26.3911C190.874 27.2762 189.023 27.6785 187.092 27.6785C185.322 27.6785 183.552 27.1957 181.943 26.3106ZM190.311 23.1727C191.276 22.6094 192.081 21.8853 192.644 20.8393C193.207 19.7933 193.529 18.506 193.529 17.0577C193.529 15.5289 193.207 14.322 192.644 13.276C192.081 12.23 191.276 11.4254 190.391 10.9427C189.426 10.3794 188.46 10.1381 187.334 10.1381C186.207 10.1381 185.242 10.3794 184.276 10.9427C183.31 11.5059 182.586 12.23 182.023 13.276C181.46 14.322 181.218 15.6094 181.218 17.0577C181.218 19.3106 181.782 21.0002 182.908 22.2071C184.035 23.4141 185.483 24.0577 187.173 24.0577C188.38 23.9773 189.345 23.7359 190.311 23.1727Z'
      />
      <path
        fill={color}
        d='M207.449 23.4945L213.322 6.67822H217.828L209.943 27.2761H204.874L197.069 6.67822H201.656L207.449 23.4945Z'
      />
      <path
        fill={color}
        d='M237.058 18.5864H221.288C221.449 20.1956 222.012 21.5635 223.138 22.529C224.265 23.4945 225.552 24.0577 227.162 24.0577C229.495 24.0577 231.104 23.0922 231.989 21.1612H236.575C235.932 23.0922 234.805 24.621 233.196 25.8279C231.587 27.0348 229.575 27.6785 227.081 27.6785C225.15 27.6785 223.38 27.2762 221.771 26.3911C220.242 25.506 218.954 24.2991 218.069 22.6899C217.184 21.0807 216.782 19.2301 216.782 17.1381C216.782 15.0462 217.184 13.1151 218.069 11.5059C218.954 9.89668 220.161 8.68977 221.69 7.8047C223.219 6.91964 225.069 6.51733 227.081 6.51733C229.012 6.51733 230.782 6.91964 232.311 7.8047C233.84 8.68977 235.047 9.81622 235.851 11.345C236.736 12.8737 237.139 14.6439 237.139 16.6554C237.219 17.2991 237.139 18.0232 237.058 18.5864ZM232.794 15.2071C232.794 13.5979 232.23 12.391 231.104 11.4254C229.978 10.4599 228.61 9.97714 227.001 9.97714C225.552 9.97714 224.265 10.4599 223.219 11.345C222.173 12.3105 221.529 13.5174 221.368 15.1266H232.794V15.2071Z'
      />
    </svg>
  );
};

SupermoveLogo.COLORS = {
  WHITE: colors.white,
  RED: colors.red.branding,
  GREEN: colors.green.branding,
  BLUE: colors.blue.branding,
  YELLOW: colors.yellow.branding,
  BLACK: colors.black,
};

// --------------------------------------------------
// Props
// --------------------------------------------------

SupermoveLogo.defaultProps = {
  color: SupermoveLogo.COLORS.WHITE,
  size: null,
};

// @ts-expect-error TS doesn't play well with assigning to defined functions
SupermoveLogo.Icon.defaultProps = {
  color: SupermoveLogo.COLORS.WHITE,
  backgroundColor: SupermoveLogo.COLORS.BLACK,
  size: null,
};

// @ts-expect-error TS doesn't play well with assigning to defined functions
SupermoveLogo.Name.defaultProps = {
  color: SupermoveLogo.COLORS.WHITE,
  size: null,
};

export default SupermoveLogo;
