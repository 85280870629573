// Libraries
import ReactDOM from 'react-dom';

import {useCallback, useRef} from '@supermove/hooks';

/**
 * This hook watches for presses outside of an element
 * and invokes the given callback when such a press happens.
 * An example where this is useful is for a dropdown that
 * should close when a press outside of the dropdown happens.
 */
const useOnPressOutside = (onPressOutside: () => unknown) => {
  const ref = useRef<Element | Text | null>();

  const handlePress = useCallback(
    (event) => {
      if (!ref.current?.contains(event.target)) {
        onPressOutside();
      }
    },
    [onPressOutside],
  );

  const refCallback = useCallback(
    (item) => {
      if (ref.current) {
        document.removeEventListener('mousedown', handlePress);
        document.removeEventListener('touchstart', handlePress);
      }

      // We have to look up the actual DOM node.
      const node = ReactDOM.findDOMNode(item);
      ref.current = node;

      if (ref.current) {
        document.addEventListener('mousedown', handlePress);
        document.addEventListener('touchstart', handlePress);
      }
    },
    [handlePress],
  );

  return refCallback;
};

export default useOnPressOutside;
