// Libraries
import FlatList from '@react-x/flat-list';
import _ from 'lodash';
import React from 'react';
// @ts-expect-error library is not typed
import {Document, Page} from 'react-pdf';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import './AnnotationLayer.css';

// Supermove
import {useState} from '@supermove/hooks';

// Components
import Styled from '../Styled';

import {PdfViewProps} from './PdfViewTypes';

const Container = Styled.View`
  flex: 1;
`;

const PdfView = ({source, onLoad = () => {}}: PdfViewProps) => {
  const [pagesCount, setPagesCount] = useState(0);
  const pageNumbers = _.range(pagesCount);

  return (
    <Container>
      <Document
        file={source.uri}
        onLoadSuccess={(pdf: {numPages: number}) => {
          setPagesCount(pdf.numPages);
          onLoad({numberOfPages: pdf.numPages});
        }}
      >
        <FlatList
          initialNumToRender={1}
          data={pageNumbers}
          keyExtractor={(number: number, index: number) => String(index)}
          renderItem={({item, index}: {item: number; index: number}) => (
            <Page pageNumber={index + 1} />
          )}
        />
      </Document>
    </Container>
  );
};

// Standard A4 dimensions
const PAGE_HEIGHT = 841.89;
const PAGE_WIDTH = 595.28;
PdfView.PAGE_ASPECT_RATIO = PAGE_HEIGHT / PAGE_WIDTH;

export default PdfView;
