// Libraries
import ScrollView from '@react-x/scroll-view';
import React from 'react';

// Components
import Styled from '../../Styled';

const TabBarContainer = Styled.View`
`;

const ScrollViewContainer = Styled.View`
`;

const FullWidthBar = Styled.View<{color?: string}>`
  height: 4px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  background-color: ${({color}) => color};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const DisplayTabs = ({
  children,
  selectedIndex,
  handleChangeIndex,
  TabComponent,
}: {
  children: React.ReactNode;
  selectedIndex: number;
  handleChangeIndex: (index: number) => void;
  TabComponent: (props: {
    tab: any;
    index: number;
    isSelected: boolean;
    handlePress: () => void;
  }) => JSX.Element;
}) => {
  return (
    <React.Fragment>
      {/* @ts-expect-error React says props doesn't exist */}
      {React.Children.map(children, ({props}, index) => {
        return (
          <TabComponent
            key={index}
            tab={props.tab}
            index={index}
            isSelected={index === selectedIndex}
            handlePress={() => handleChangeIndex(index)}
          />
        );
      })}
    </React.Fragment>
  );
};

export interface TabBarProps {
  children: React.ReactNode;
  selectedIndex: number;
  handleChangeIndex: (index: number) => void;
  TabComponent: any;
  scrollEnabled?: boolean;
  showFullWidthBar?: boolean;
  fullWidthBarColor?: string;
  fullWidthBarStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  showsHorizontalScrollIndicator?: boolean;
  scrollViewRef?: React.MutableRefObject<any>;
}

const TabBar = ({
  children,
  selectedIndex,
  handleChangeIndex,
  TabComponent,
  scrollEnabled,
  showFullWidthBar,
  fullWidthBarColor,
  fullWidthBarStyle,
  style,
  showsHorizontalScrollIndicator,
  scrollViewRef,
}: TabBarProps) => {
  return (
    <TabBarContainer style={style}>
      {scrollEnabled ? (
        <ScrollViewContainer>
          {showFullWidthBar && <FullWidthBar color={fullWidthBarColor} style={fullWidthBarStyle} />}
          <ScrollView
            ref={scrollViewRef}
            horizontal
            showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
          >
            <DisplayTabs
              children={children}
              selectedIndex={selectedIndex}
              handleChangeIndex={handleChangeIndex}
              TabComponent={TabComponent}
            />
          </ScrollView>
        </ScrollViewContainer>
      ) : (
        <Row>
          <DisplayTabs
            children={children}
            selectedIndex={selectedIndex}
            handleChangeIndex={handleChangeIndex}
            TabComponent={TabComponent}
          />
        </Row>
      )}
    </TabBarContainer>
  );
};

export default TabBar;
