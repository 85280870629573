// Libraries
import arrayMove from 'array-move';

const move = <T>({list, fromIndex, toIndex}: {list: T[]; fromIndex: number; toIndex: number}) => {
  return arrayMove(list, fromIndex, toIndex);
};

const remove = <T>(list: T[], index: number) => {
  const front = list.slice(0, index);
  const back = list.slice(index + 1, list.length);
  return [...front, ...back];
};

const insert = <T>(list: T[], element: T, index?: number) => {
  const insertIndex = index ?? list.length;
  const front = list.slice(0, insertIndex);
  const back = list.slice(insertIndex, list.length);
  return [...front, element, ...back];
};

const insertIf = <T>(condition: boolean, ...elements: T[]) => {
  return condition ? elements : [];
};

const List = {
  move,
  remove,
  insert,
  insertIf,
};

export default List;
