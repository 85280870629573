// Libraries
import {TouchableOpacity} from '@react-x/touchable';
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {colors} from '@supermove/styles';

// Components
import Styled from './Styled';

export type ButtonProps = React.ComponentProps<typeof TouchableOpacity> & {
  activeOpacity?: number;
  children?: React.ReactNode;
  color?: string;
  disabledColor?: string;
  disabled?: boolean;
};

const Button = Styled<React.ComponentType<ButtonProps>, ButtonProps>(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 5px;
  background-color: ${(props) => (props.disabled ? props.disabledColor : props.color)};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

Button.propTypes = {
  width: PropTypes.number,
  color: PropTypes.string,
  disabledColor: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  activeOpacity: 0.8,
  color: colors.blue.interactive,
  disabledColor: colors.gray.tertiary,
  disabled: false,
};

export default Button;
