// Libraries
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
// @ts-expect-error library is not typed
import {DayPickerSingleDateController} from 'react-dates';

// Initialize react-dates package and styles
import 'react-dates/initialize';
// First, we revert the global display style, then we set the custom styles
// The order of these imports matter for that reason
import './SingleDatePickerRevertGlobslDisplayStyle.css';
import 'react-dates/lib/css/_datepicker.css';
import './SingleDatePickerCustomStyles.css';

// Supermove
import {Styled} from '@supermove/components';
import {useDebouncedCallback} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

const CustomDayContainer = Styled.View<{
  isSelected?: boolean;
  isToday?: boolean;
  dayColor: {backgroundColor?: string};
}>`
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: ${({isSelected, isToday, dayColor}) => {
      if (isSelected) {
        return colors.blue.interactive;
      }
      if (isToday) {
        return colors.blue.accent;
      }
      return dayColor.backgroundColor || colors.white;
    }};
    border-radius: ${({isSelected, isToday}) => {
      if (isSelected || isToday) {
        return '50%';
      }
      return '0%';
    }};
`;

const CustomDayText = Styled.Text<{
  vars: {isSelected?: boolean; isToday: boolean; dayColor: {textColor?: string}};
}>`
    ${Typography.Subheading}
    color: ${({vars}) => {
      if (vars.isSelected) {
        return colors.white;
      }
      if (vars.isToday) {
        return colors.blue.interactive;
      }
      return vars.dayColor.textColor || colors.gray.secondary;
    }};
`;

const CustomDayDecoratorContainer = Styled.View`
    flex-direction: row;
    position: absolute;
`;

const CustomDayDecoratorRow = ({
  marginBottom,
  style,
  children,
}: {
  marginBottom?: number;
  style: React.CSSProperties;
  children: React.ReactNode;
}) => {
  return (
    <CustomDayDecoratorContainer
      style={{display: 'flex', bottom: marginBottom || 4, ...(style || {})}}
    >
      {children}
    </CustomDayDecoratorContainer>
  );
};

interface CustomDayProps {
  day: moment.Moment;
  modifiers: Set<string>;
  dayColors: {[key: string]: {backgroundColor?: string; textColor?: string}};
  dayContainerStyle?: React.CSSProperties;
  dayTextStyle?: React.CSSProperties;
  DayDecoratorComponent: React.FC<{date: string; isSelected: boolean; isToday: boolean}>;
}

const CustomDay = ({
  day,
  modifiers,
  dayColors,
  dayContainerStyle,
  dayTextStyle,
  DayDecoratorComponent,
}: CustomDayProps) => {
  const isSelected = modifiers.has('selected');
  const isToday = modifiers.has('today');
  const date = day.format('YYYY-MM-DD');
  const dayColor = _.get(dayColors, date, {});

  return (
    <CustomDayContainer
      isSelected={isSelected}
      isToday={isToday}
      dayColor={dayColor}
      style={{display: 'flex', ...dayContainerStyle}}
    >
      <CustomDayText vars={{isSelected, isToday, dayColor}} style={dayTextStyle}>
        {day.format('D').padStart(2, '0')}
      </CustomDayText>
      {/* Note: You should create your DayDecoratorComponent with SingleDatePicker.DayDecoratorRow.
      If you need to create a more custom decorator component, you may need to add inline styling
      with {display: 'flex'} since react-dates does not default divs to flex. */}
      <DayDecoratorComponent date={date} isSelected={isSelected} isToday={isToday} />
    </CustomDayContainer>
  );
};

const CustomDaySkeleton = () => {
  return <CustomDayContainer dayColor={{backgroundColor: colors.gray.border}} />;
};

interface SingleDatePickerProps {
  selectedDate: string;
  handleDateChange: (date: string) => void;
  handleViewingMonthChange: (date: string) => void;
  viewingMonth: string;
  dayColors: {[key: string]: {backgroundColor?: string; textColor?: string}};
  daySize?: number;
  loading: boolean;
  noBorder?: boolean;
  noNavButtons?: boolean;
  hideMonthText?: boolean;
  dayContainerStyle?: React.CSSProperties;
  dayTextStyle?: React.CSSProperties;
  DayDecoratorComponent?: React.FC<{date: string; isSelected: boolean; isToday: boolean}>;
}

const SingleDatePicker = ({
  selectedDate,
  handleDateChange,
  handleViewingMonthChange,
  viewingMonth,
  dayColors,
  daySize,
  loading,
  noBorder = false,
  noNavButtons = false,
  hideMonthText = false,
  dayContainerStyle = {},
  dayTextStyle = {},
  DayDecoratorComponent = () => null,
}: SingleDatePickerProps) => {
  const selectedDateMoment = moment(selectedDate);
  const viewingMonthMoment = moment(viewingMonth);
  const handleDebouncedMonthChange = useDebouncedCallback(handleViewingMonthChange, 500);

  return (
    <React.Fragment>
      {hideMonthText && (
        <style>
          {`
          /* Add CSS to hide the Month Year display */
          .CalendarMonth_caption {
            visibility: hidden;
          }
        `}
        </style>
      )}
      <DayPickerSingleDateController
        date={selectedDateMoment}
        onDateChange={(date: moment.Moment) => handleDateChange(date.format('YYYY-MM-DD'))}
        numberOfMonths={1}
        focused
        daySize={daySize}
        noBorder={noBorder}
        noNavButtons={noNavButtons}
        initialVisibleMonth={() => viewingMonthMoment}
        onPrevMonthClick={(newCurrentMonth: moment.Moment) =>
          handleDebouncedMonthChange(newCurrentMonth.format('YYYY-MM-DD'))
        }
        onNextMonthClick={(newCurrentMonth: moment.Moment) =>
          handleDebouncedMonthChange(newCurrentMonth.format('YYYY-MM-DD'))
        }
        hideKeyboardShortcutsPanel
        renderDayContents={(day: moment.Moment, modifiers: Set<string>) => {
          if (loading) {
            // @ts-expect-error it doesn't like moment as key
            return <CustomDaySkeleton key={day} />;
          }
          return (
            <CustomDay
              // @ts-expect-error it doesn't like moment as key
              key={day}
              day={day}
              modifiers={modifiers}
              dayColors={dayColors}
              dayContainerStyle={dayContainerStyle}
              dayTextStyle={dayTextStyle}
              DayDecoratorComponent={DayDecoratorComponent}
            />
          );
        }}
      />
    </React.Fragment>
  );
};

SingleDatePicker.DayDecoratorRow = CustomDayDecoratorRow;

export default SingleDatePicker;
