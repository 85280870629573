// Libraries
import currency from 'currency.js';
import React from 'react';

interface CurrencyPickerProps {
  shouldHideCentsIfZero?: boolean;
  component: React.ComponentType<any>;
  value: string;
  onChangeText: (value: string) => void;
  options?: Record<string, unknown>;
  onBlur?: (event: any) => void;
}

class CurrencyPicker extends React.Component<CurrencyPickerProps> {
  getMatches = (string: string, regex: RegExp) => {
    return string.match(regex) || [];
  };

  formatValue = (value: string) => {
    if (value === '') {
      return '';
    }

    // Allow `-` for negative prices and allow `$` for matching.
    const numerics = this.getMatches(value, /[-]?[$]?[.]?\d+[.\d+]?/g).join('');
    const alpha = this.getMatches(value, /[a-zA-Z]+/g)
      .join('')
      .toLowerCase();

    if (alpha.length > 0) {
      return '';
    }

    const formatted = currency(numerics).format(true);
    if (this.props.shouldHideCentsIfZero) {
      return formatted.replace(/\.00$/, '');
    } else {
      return formatted;
    }
  };

  handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    this.props.onChangeText(this.formatValue(this.props.value));
  };

  render() {
    const {
      shouldHideCentsIfZero,
      component: InputComponent = 'input',
      value,
      onChangeText,
      options,
      onBlur,
      ...props
    } = this.props;

    return (
      <InputComponent
        {...props}
        value={value}
        // @ts-expect-error TS says this doesn't exist
        onChangeText={onChangeText}
        onBlur={(event) => {
          onBlur && onBlur(event);
          this.handleBlur(event);
        }}
        options={options ?? {}}
      />
    );
  }
}

export default CurrencyPicker;
