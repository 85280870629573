// Libraries
import {TouchableOpacity} from '@react-x/touchable';

// Components
import Styled from './Styled';

const ButtonV2 = Styled(TouchableOpacity)`
`;

ButtonV2.propTypes = {};

ButtonV2.defaultProps = {
  activeOpacity: 0.8,
};

export default ButtonV2;
