// Libraries
import React from 'react';
// @ts-expect-error library is not typed
import ReactLinkify from 'react-linkify';

interface LinkifyProps {
  component: (href: string, text: string, key: string) => React.ReactNode;
  children: React.ReactNode;
}

const Linkify = ({component, children}: LinkifyProps) => {
  return <ReactLinkify componentDecorator={component}>{children}</ReactLinkify>;
};

export default Linkify;
