// Libraries
import _ from 'lodash';

const Float = {
  // Used for Form objects.
  toForm: (value?: number | null | string, isNullAllowed = false) => {
    if (isNullAllowed) {
      return _.isNil(value) ? '' : String(value);
    }
    return Float.toString(value as number);
  },
  toMutation: (value: string | null | undefined, isNullAllowed = false) => {
    if (isNullAllowed && (_.isNil(value) || value === '')) {
      return null;
    }
    return Float.toFloat(value);
  },

  display: <T>(value: T) => value,
  toFloat: (value: string | null | undefined, defaultValue = 0): number => {
    if (value === null || value === undefined) {
      return defaultValue;
    }
    return parseFloat(value) || defaultValue;
  },
  toPercentage: (value: number) => _.round(value / 100),
  toString: (float: number) => (float ? String(float) : ''),
  toOrdinal: (float: number) => {
    const int = Math.round(float);
    if (int > 3 && int < 21) return `${int}th`;
    switch (int % 10) {
      case 1:
        return `${int}st`;
      case 2:
        return `${int}nd`;
      case 3:
        return `${int}rd`;
      default:
        return `${int}th`;
    }
  },
  toLowercaseLetter: (float: number) => String.fromCharCode(97 + float),
};

export default Float;
