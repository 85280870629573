import {DocumentNode} from 'graphql';
import {useContext} from 'react';
import {
  useQuery as useQueryApollo,
  useMutation as useMutationApollo,
  Context,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
} from 'react-apollo';

import {PageContext} from '@supermove/analytics';

/**
 * Adds additional HTTP headers to the given Apollo Context object.
 *
 * The 'headers' field is used by the middleware in ./apollo.js
 */
export const useApolloContextWithHeaders = (context?: Context): Context => {
  if (!context) {
    context = {};
  }

  const {pageName, appNamespace} = useContext(PageContext);
  context.headers = {
    ...context.headers,
    ...(appNamespace ? {'x-supermove-app-namespace': appNamespace} : {}),
    ...(pageName ? {'x-supermove-page-name': pageName} : {}),
  };
  return context;
};

/**
 * Wrapper around Apollo's useQuery hook that injects additional HTTP headers
 */
export const useQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> => {
  if (!options) {
    options = {};
  }
  options.context = useApolloContextWithHeaders(options.context);
  return useQueryApollo(query, options);
};

/**
 * Wrapper around Apollo's useMutation hook that injects additional HTTP headers
 */
export const useMutation = <TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> => {
  if (!options) {
    options = {};
  }
  options.context = useApolloContextWithHeaders(options.context);
  return useMutationApollo(mutation, options);
};
