// Libraries
import {useState, useEffect} from 'react';

/**
 * Returns the time the prop was updated at. If the prop is updated to the same value,
 * `updatedAt` remains unchanged.
 */
const usePropUpdatedAt = (prop: unknown) => {
  const [updatedAt, setUpdatedAt] = useState(new Date());

  useEffect(() => {
    setUpdatedAt(new Date());
  }, [prop]);

  return {
    updatedAt,
  };
};

export default usePropUpdatedAt;
