// Libraries
import React from 'react';

// Supermove
import {Lifecycle} from '@supermove/components';

import AnalyticsContext, {IdentifyType} from '../context/AnalyticsContext';
import {VitallyType} from '../services/Vitally/VitallyTypes';

const Identify = ({
  isEnabledOfficeVitallyNps,
  viewer,
  vitally,
  children,
}: {
  isEnabledOfficeVitallyNps: boolean;
  viewer: IdentifyType;
  vitally: VitallyType;
  children: React.ReactNode;
}) => {
  return (
    <AnalyticsContext.Consumer>
      {(analytics) => (
        <Lifecycle
          onMount={() => {
            analytics.identify(viewer);

            if (isEnabledOfficeVitallyNps) {
              vitally.nps({productName: 'Supermove'});
            }
          }}
          children={children}
        />
      )}
    </AnalyticsContext.Consumer>
  );
};

export default Identify;
