// Libraries
import React from 'react';

// Supermove
import GlobalAppWrapper from '@supermove/app/src/components/GlobalAppWrapper';
import SupermoveErrorView from '@supermove/app/src/components/SupermoveErrorView';
import {gql} from '@supermove/graphql';
import {useEffect} from '@supermove/hooks';
import {UserModel} from '@supermove/models';
import {Storage} from '@supermove/sdk';

const SupermoveApp = ({
  name,
  version,
  buildNumber,
  showOfflineOverlay,
  onError,
  isAuthenticated,
  viewer,
  children,
}: {
  name: string;
  version: string;
  buildNumber: number;
  showOfflineOverlay: boolean;
  onError: (error: Error) => void;
  isAuthenticated?: boolean;
  viewer?: UserModel;
  children: React.ReactNode;
}) => {
  useEffect(() => {
    if (viewer?.viewingOrganization?.features?.isEnabledXAPIExperimental) {
      Storage.setItem('X_API_EXPERIMENTAL', 'true');
    } else {
      Storage.setItem('X_API_EXPERIMENTAL', 'false');
    }
  }, [viewer]);

  return (
    <GlobalAppWrapper
      showOfflineOverlay={showOfflineOverlay}
      name={name}
      version={version}
      buildNumber={buildNumber}
    >
      <SupermoveErrorView
        version={version}
        buildNumber={buildNumber}
        onError={onError}
        isAuthenticated={isAuthenticated}
        viewer={viewer}
      >
        {children}
      </SupermoveErrorView>
    </GlobalAppWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SupermoveApp.fragment = gql`
  ${SupermoveErrorView.fragment}

  fragment SupermoveApp on User {
    id
    viewingOrganization {
      id
      features {
        isEnabledXAPIExperimental: isEnabled(feature: "X_API_EXPERIMENTAL")
      }
    }
    ...SupermoveErrorView
  }
`;

export default SupermoveApp;
