// Libraries
import React from 'react';

import {Form} from '@supermove/hooks';

import TimePicker from './TimePicker';
import {TimePickerProps} from './TimePickerTypes';

interface TimeInputProps extends TimePickerProps {
  disabled?: boolean;
  component?: any;
  name: string;
  placeholder?: string;
  value: string;
  setFieldValue: Form['setFieldValue'];
  style?: React.CSSProperties;
  onChangeTime?: (value?: string) => void;
}

const TimeInput = ({
  disabled,
  component,
  name,
  placeholder,
  value,
  setFieldValue,
  style,
  onChangeTime = () => {},
  ...props
}: TimeInputProps) => (
  <TimePicker
    {...props}
    disabled={disabled}
    placeholder={placeholder}
    value={value}
    component={component}
    onChange={(value) => {
      setFieldValue(name, value);
      onChangeTime(value);
    }}
    style={style}
  />
);

export default TimeInput;
