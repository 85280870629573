/**
 * Single object that we add all other components to. This is used via:
 *
 *   import {Styled} from '@supermove/components';
 *
 *   const Container = Styled.View`
 *     flex: 1;
 *     padding-horizontal: 20px;
 *   `;
 */

// Libraries
import ActivityIndicator from '@react-x/activity-indicator';
import Image from '@react-x/image';
import Text from '@react-x/text';
import {TouchableOpacity} from '@react-x/touchable';
import View from '@react-x/view';
import {Interpolation, ThemedStyledProps, StyledComponent} from 'styled-components';
// @ts-expect-error - styled-components types doesn't cover /primatives
import styled from 'styled-components/primitives';

import Button from './Button';
import ButtonV2 from './ButtonV2';
import LoadingButton from './LoadingButton';
import SecondaryButton from './SecondaryButton';
import StatefulButton from './StatefulButton';
import TextInput from './TextInput';

type StyledFunction<C extends React.ComponentType<any>, P extends object = {}> = <
  Props extends object = P,
>(
  strings: TemplateStringsArray,
  ...interpolations: Interpolation<ThemedStyledProps<Props, any>>[]
) => StyledComponent<C, any, Props, never>;

interface StyledType {
  <C extends React.ComponentType<any>, P extends object = {}>(component: C): StyledFunction<C, P>;
  Image: StyledFunction<typeof Image>;
  Text: StyledFunction<typeof Text>;
  View: StyledFunction<typeof View>;

  Loading: StyledFunction<typeof ActivityIndicator>;
  Touchable: StyledFunction<typeof TouchableOpacity>;

  Button: StyledFunction<typeof Button>;
  ButtonV2: StyledFunction<typeof ButtonV2>;
  SecondaryButton: StyledFunction<typeof SecondaryButton>;
  LoadingButton: StyledFunction<typeof LoadingButton>;
  StatefulButton: StyledFunction<typeof StatefulButton>;

  H1: StyledFunction<typeof Text>;
  H2: StyledFunction<typeof Text>;
  H3: StyledFunction<typeof Text>;
  H4: StyledFunction<typeof Text>;
  H5: StyledFunction<typeof Text>;
  H6: StyledFunction<typeof Text>;
  H7: StyledFunction<typeof Text>;
  H8: StyledFunction<typeof Text>;

  TextInput: StyledFunction<typeof TextInput> & {
    Plain: StyledFunction<typeof TextInput.Plain>;
    H1: StyledFunction<typeof TextInput.H1>;
    H2: StyledFunction<typeof TextInput.H2>;
    H3: StyledFunction<typeof TextInput.H3>;
    H4: StyledFunction<typeof TextInput.H4>;
    H5: StyledFunction<typeof TextInput.H5>;
    H6: StyledFunction<typeof TextInput.H6>;
    H7: StyledFunction<typeof TextInput.H7>;
  };
}

const Styled: StyledType = ((Component: React.ComponentType<any>) =>
  styled(Component)) as StyledType;

export default Styled;
