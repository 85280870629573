// Supermove
import {useAnalytics} from '@supermove/analytics';

interface UseTrackEventArgs {
  eventName: string;
  eventType?: string;
  pageName?: string;
  url?: string;
  additionalData?: Record<string, any>;
}

// TODO(mark): pageName and url should come from a route context object so that we don't need
// to pass it in each time.
const useTrackEvent = ({
  eventName,
  eventType,
  pageName,
  url,
  additionalData = {},
}: UseTrackEventArgs) => {
  const analytics = useAnalytics();

  const trackEvent = () => {
    analytics.track({
      event: eventName,
      data: {
        'Type': eventType,
        'Page Name': pageName,
        'URL': url,
        ...additionalData,
      },
    });
  };

  const trackEventHandler = <T>(handler: (...args: T[]) => unknown) => {
    return (...args: T[]) => {
      trackEvent();
      return handler(...args);
    };
  };

  return {
    trackEvent,
    trackEventHandler,
  };
};

export default useTrackEvent;
