// Libraries
import {useEffect, useState} from 'react';

// Supermove
import {useMemo, useMountEffect} from '@supermove/hooks';

// Analytics
import {AnalyticsContextType} from '../../context/AnalyticsContext';
import useAnalytics from '../../hooks/useAnalytics';

import {UseZendeskWebWidgetType, ZendeskType} from './ZendeskWebWidgetTypes';

// This object is global so it does not cause re-renders.
const Zendesk: ZendeskType = {
  open: () => {
    // @ts-expect-error this function comes from the zendesk plugin
    if (window.zE) {
      // Before opening the widget, we must make it visible. This will be closed via the handler
      // for onClose.
      // @ts-expect-error this function comes from the zendesk plugin
      window.zE('messenger', 'show');

      // Opens the Zendesk chat widget:
      // https://developer.zendesk.com/api-reference/widget-messaging/web/core/#open
      // @ts-expect-error this function comes from the zendesk plugin
      window.zE('messenger', 'open');
    }
  },
  show: () => {
    // @ts-expect-error this function comes from the zendesk plugin
    if (window.zE) {
      // @ts-expect-error this function comes from the zendesk plugin
      window.zE('messenger', 'show');
    }
  },
  hide: () => {
    // @ts-expect-error this function comes from the zendesk plugin
    if (window.zE) {
      // @ts-expect-error this function comes from the zendesk plugin
      window.zE('messenger', 'hide');
    }
  },

  // Callbacks
  handleOnClose: (onClose: () => unknown) => {
    // @ts-expect-error this function comes from the zendesk plugin
    if (window.zE) {
      // @ts-expect-error this function comes from the zendesk plugin
      window.zE('messenger:on', 'close', onClose);
    }
  },
};

const initializeZendesk = (analytics: AnalyticsContextType): ZendeskType => {
  // Since window is only available in the browser, we need to call the track function dynamically
  // whenever an event occurs.
  const trackEvent = ({event}: {event: string}) => {
    analytics.track({
      event,
      data: {
        Type: 'Zendesk Action',
        URL: window.location.pathname,
      },
    });
  };

  return {
    open: () => {
      trackEvent({event: 'Zendesk Widget Opened'});
      Zendesk.open();
    },
    show: () => {
      trackEvent({event: 'Zendesk Widget Shown'});
      Zendesk.show();
    },
    hide: () => {
      trackEvent({event: 'Zendesk Widget Hidden'});
      Zendesk.hide();
    },
    // No events needed for callbacks.
    handleOnClose: (onClose: () => unknown) => {
      Zendesk.handleOnClose(onClose);
    },
  };
};

const useZendeskWebWidget: UseZendeskWebWidgetType = ({widgetKey, isVisible, options}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const analytics = useAnalytics();
  const zendesk = useMemo(() => initializeZendesk(analytics), [analytics]);

  useMountEffect(() => {
    // If the widgetKey isn't provided, do not load the script.
    if (!widgetKey) {
      return;
    }

    // If the Zendesk widget has already been loaded, do not load again.
    // @ts-expect-error this setting comes from the zendesk plugin
    if (window.zESettings) {
      return;
    }

    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${widgetKey}`;
    script.async = true;
    script.onload = () => {
      // After loading has finished, we register handlers for Zendesk events.
      zendesk.handleOnClose(() => {
        // If the widget isn't visible, then we hide the chat when the user is finished with it.
        if (!isVisible) {
          // Skip analytics by calling the global zendesk immediately.
          Zendesk.hide();
        }
      });

      setIsLoaded(true);
    };

    // Set the settings on the global zendesk settings object.
    // @ts-expect-error this setting comes from the zendesk plugin
    window.zESettings = {...options};

    // Add and remove the script from the DOM.
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  });

  // Once loaded, we let the isVisible flag handle showing and hiding the zendesk widget.
  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    if (isVisible) {
      Zendesk.show();
    } else {
      Zendesk.hide();
    }
  }, [isVisible, isLoaded]);

  return zendesk;
};

export default useZendeskWebWidget;
