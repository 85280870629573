// Libraries
import React from 'react';
// @ts-expect-error
import ReactSidebar from 'react-sidebar';

interface SidebarProps extends Record<string, unknown> {
  isOpen: boolean;
  position?: 'left' | 'right';
  onOpen: () => void;
  onClose: () => void;
  content: React.ReactNode;
  children: React.ReactNode;
}

const Sidebar = ({
  isOpen,
  position,
  onOpen,
  onClose,
  content,
  children,
  ...props
}: SidebarProps) => {
  return (
    <ReactSidebar
      open={isOpen}
      pullRight={position === 'right'}
      pullLeft={position === 'left'}
      sidebar={content}
      onSetOpen={(open: boolean) => (open ? onOpen() : onClose())}
      children={children}
      {...props}
    />
  );
};

export default Sidebar;
