// Libraries
import {keyframes} from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
`;

const slideOutToRight = keyframes`
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
`;

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0%);
  }
`;

const slideOutToLeft = keyframes`
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
`;

const slideInFromTop = keyframes`
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
`;

const slideOutToTop = keyframes`
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-100%);
  }
`;

const slideInFromBottom = keyframes`
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
`;

const slideOutToBottom = keyframes`
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(100%);
  }
`;

const Animations = {
  fadeIn,
  fadeOut,
  slideInFromRight,
  slideOutToRight,
  slideInFromLeft,
  slideOutToLeft,
  slideInFromTop,
  slideOutToTop,
  slideInFromBottom,
  slideOutToBottom,
};

export default Animations;
