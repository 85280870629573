// Libraries
import * as Sentry from '@sentry/react';
import React from 'react';

import {SentryHandleIdentifyArgs, SentryHandleTrackArgs, SentryProps} from './SentryTypes';

class SentryIntegration extends React.Component<SentryProps> {
  // eslint-disable-next-line
  get isEnabled() {
    return true;
  }

  handleIdentify = ({id, firstName, lastName, email, ...rest}: SentryHandleIdentifyArgs) => {};

  handleTrack = ({event, data}: SentryHandleTrackArgs) => {
    // Override the default behavior of using the current URL as the transaction name. We don't want things like IDs to
    // be part of the transaction name, since that makes it difficult to aggregate the performance of pages.
    // Sentry provides built-in integrations for some react routing libraries, but we need to manually
    // set the transaction names because we don't use the same libraries across our apps
    const eventName = event.replace(/^Viewed /, '');
    if (eventName) {
      Sentry.configureScope((scope) => {
        scope.setTransactionName(eventName);
      });
    }
  };

  handleReset = () => {};

  render() {
    return this.props.children({
      identify: this.handleIdentify,
      track: this.handleTrack,
      reset: this.handleReset,
    });
  }
}

export default SentryIntegration;
