// @ts-expect-error library is not typed
import {Mutation as IncrementMutation} from '@increment/components';
import React from 'react';

import {useApolloContextWithHeaders} from '@supermove/graphql';

interface MutationProps {
  children: React.ReactNode;
  context: Parameters<typeof useApolloContextWithHeaders>[0];
}

/**
 * Wrapper around Apollo's Mutation component that injects additional HTTP headers
 */
const Mutation = ({children, context, ...props}: MutationProps) => {
  return (
    <IncrementMutation context={useApolloContextWithHeaders(context)} {...props}>
      {children}
    </IncrementMutation>
  );
};

export default Mutation;
