// Libraries
import React from 'react';

// App
import {colors} from '@supermove/styles';

// Components
import Icon from '../Icon';
import Styled from '../Styled';

const Touchable = Styled.Touchable`
`;

const Container = Styled.View<{
  size?: number;
  disabled?: boolean;
  checked: boolean;
  color: string;
  borderColor?: string;
}>`
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background-color: ${(props) =>
    props.disabled ? colors.gray.border : props.checked ? props.color : colors.transparent};
  border-color: ${(props) => (props.borderColor ? props.borderColor : props.color)};
  border-radius: 2px;
  border-width: 2px;
`;

interface CheckboxProps {
  borderColor?: string;
  disabled?: boolean;
  checked: boolean;
  size?: number;
  iconSize?: number;
  color?: string;
  onChange: (checked: boolean) => void;
  style?: React.CSSProperties;
}

const Checkbox = ({
  disabled = false,
  checked,
  size = 20,
  iconSize = 16,
  color = colors.gray.secondary,
  borderColor,
  onChange,
  style = {},
}: CheckboxProps) => {
  return (
    <Touchable
      disabled={disabled}
      activeOpacity={1}
      onPress={() => onChange(!checked)}
      style={style}
    >
      <Container
        checked={checked}
        disabled={disabled}
        size={size}
        color={color}
        borderColor={borderColor}
      >
        <Icon
          color={disabled ? colors.gray.border : colors.white}
          size={iconSize}
          source={Icon.Check}
        />
      </Container>
    </Touchable>
  );
};

export default Checkbox;
