// Libraries
import {InMemoryCache} from 'apollo-cache-inmemory';
import {ApolloClient, DefaultOptions, ApolloClientOptions} from 'apollo-client';
import {ApolloLink} from 'apollo-link';
import {setContext} from 'apollo-link-context';
import {ErrorResponse, onError as onGraphQLError} from 'apollo-link-error';
import {RestLink} from 'apollo-link-rest';
import {RetryLink} from 'apollo-link-retry';
import {createUploadLink} from 'apollo-upload-client';
import merge from 'lodash.merge';

/**
 * GraphQL context object passed to Query components that need to use RestLink, instead of the /graphql API endpoint
 */
const RestContext = {
  useRest: true,
};

const restLink = new RestLink({
  uri: '', // Empty uri to silence console warnings - @rest directives should use the 'endpoint' field
  endpoints: {
    static: '/',
  },
});

const createErrorMiddleware = ({
  onError,
}: {
  onError: (options: ErrorResponse) => void;
}): ApolloLink => {
  return onGraphQLError((options) => onError(options));
};

const createRetryMiddleware = ({delay, attempts}: RetryLink.Options) => {
  return new RetryLink({delay, attempts});
};

const createGraphQLMiddleware = ({uri}: {uri: string}) => {
  return ApolloLink.split(
    (operation) => operation.getContext().useRest,
    // There's a version mismatch between RestLink types and ApolloLink types
    restLink as unknown as ApolloLink,
    createUploadLink({uri}) as unknown as ApolloLink,
  );
};

type GetHeadersResponse = Record<string, string | boolean | undefined | null>;

const createHeadersMiddleware = ({
  getHeaders,
}: {
  getHeaders: () => GetHeadersResponse | Promise<GetHeadersResponse>;
}) => {
  return setContext(async (request, context) => {
    const headers = await getHeaders();
    return {
      headers: {
        ...(context.headers || {}),
        // @ts-expect-error TODO(jay): look into why the request type doesn't match our usage
        ...request.headers,
        ...headers,
      },
    };
  });
};

const createAuthenticationMiddleware = ({getToken}: {getToken: () => Promise<string | null>}) => {
  return createHeadersMiddleware({
    getHeaders: async () => {
      const token = await getToken();
      return {
        Authorization: token,
      };
    },
  });
};

const DEFAULT_OPTIONS = {
  watchQuery: {
    fetchPolicy: 'cache-first',
  },
};

const typePolicies = {
  CalendarDay: {
    merge: true,
  },
  DispatchCalendarDay: {
    merge: true,
  },
  EmployeeApprovalStatus: {
    merge: true,
  },
  Organization: {
    fields: {
      features: {
        // Short for options.mergeObjects(existing, incoming).
        merge: true,
      },
      block: {
        merge: true,
      },
    },
  },
};
const createCache = (options = {}) => {
  return new InMemoryCache({
    addTypename: true,
    ...options,
    // @ts-expect-error TODO(jay): Look into why the type isn't found
    typePolicies,
  });
};

interface ClientConfig extends Omit<ApolloClientOptions<unknown>, 'cache'> {
  middleware?: ApolloLink[];
  defaultOptions?: DefaultOptions;
  cache?: InMemoryCache;
}

const createClient = (config: ClientConfig = {}) => {
  const {cache, middleware = [], defaultOptions = {}, ...options} = config;

  return new ApolloClient({
    link: ApolloLink.from(middleware),
    // @ts-expect-error TODO(jay): look into why it's declared as required, but works with optional
    cache,
    defaultOptions: merge({}, DEFAULT_OPTIONS, defaultOptions),
    ...options,
  });
};

export {
  // Client
  createCache,
  createClient,
  // Middleware
  createAuthenticationMiddleware,
  createErrorMiddleware,
  createGraphQLMiddleware,
  createHeadersMiddleware,
  createRetryMiddleware,
  RestContext,
};
