// Libraries
import React from 'react';

import PercentInputParser from './PercentInputParser';
import {PercentInputProps} from './PercentInputTypes';

const PercentInput = ({
  disabled,
  keyboardType = 'default',
  component,
  name,
  placeholder,
  value,
  onChange,
  onBlur = () => {},
  onAfterBlur = () => {},
  setFieldValue,
  setFieldTouched,
  style,
  options,
  ...props
}: PercentInputProps) => (
  <PercentInputParser
    {...props}
    name={name}
    disabled={disabled}
    keyboardType={keyboardType}
    placeholder={placeholder}
    value={value}
    component={component}
    onChangeText={(value) => setFieldValue(name, value)}
    onBlur={(event) => {
      onBlur(event);
      setFieldTouched(name, true);
    }}
    onAfterBlur={onAfterBlur}
    style={style}
    options={options}
  />
);

export default PercentInput;
