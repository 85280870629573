// Supermove
import {Environment} from '@supermove/sdk';

// Analytics
import {BrainfishType} from '../services/BrainfishWidget/BrainfishWidgetTypes';
import useBrainfishWidget from '../services/BrainfishWidget/useBrainfishWidget';
import {ZendeskType} from '../services/ZendeskWebWidget/ZendeskWebWidgetTypes';
import useZendeskWebWidget from '../services/ZendeskWebWidget/useZendeskWebWidget';

interface UseSupportToolsArgs {
  isChatVisible: boolean;
}

interface UseSupportToolsReturn {
  zendesk: ZendeskType;
  brainfish: BrainfishType;
  handleOpen: () => void;
}

type UseSuportToolsType = (args: UseSupportToolsArgs) => UseSupportToolsReturn;

const useSupportTools = ({isChatVisible}: UseSupportToolsArgs) => {
  // Zendesk Setup
  const zendeskWebWidgetKey = Environment.get('ZENDESK_WEB_WIDGET_KEY');
  const zendesk = useZendeskWebWidget({
    widgetKey: zendeskWebWidgetKey,
    isVisible: isChatVisible,
    options: {
      webWidget: {
        offset: {
          horizontal: '85px',
          vertical: '5px',
        },
      },
    },
  });

  // Brainfish Setup
  const brainfishApiKey = Environment.get('BRAINFISH_API_KEY');
  const brainfish = useBrainfishWidget({
    apiKey: brainfishApiKey,
    onOpenChat: () => {
      // TODO(mark): Send the actual query from the user to the Zendesk widget.
      zendesk.open();
    },
  });

  return {
    zendesk,
    brainfish,
    handleOpen: () => brainfish.open(),
  };
};

export default useSupportTools;
