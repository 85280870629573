// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Form, FormValues} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

import Styled from '../Styled';

const Message = Styled.Text`
  ${Typography.Body3}
  margin-top: 5px;
  color: ${colors.red.warning};
`;

interface ValidationErrorProps<T extends FormValues> {
  name: string;
  touched: Form<T>['touched'];
  errors: Form<T>['errors'];
}

const ValidationError = <T extends FormValues>({
  name,
  touched,
  errors,
}: ValidationErrorProps<T>) => {
  const isTouched = _.get(touched, name);
  const error = _.get(errors, name);

  if (!isTouched || !error) {
    return null;
  }

  return <Message>{error}</Message>;
};

export default ValidationError;
