// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const Container = Styled.View`
  position: absolute;
  left: 85px;
  bottom: 5px;
  z-index: 9999999;
`;

const Touchable = Styled.Touchable`
  background-color: ${colors.blue400};
  height: 64px;
  width: 64px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
`;

const BrainfishWidget = ({isVisible, onPress}: {isVisible: boolean; onPress: () => void}) => {
  if (!isVisible) {
    return null;
  }

  // TODO(mark): Need to figure out what to properly use for this. Missing CSS from the library.
  return (
    <Container>
      <Touchable onPress={onPress}>
        <Icon source={Icon.Comment} size={32} color={colors.white} />
      </Touchable>
    </Container>
  );
};

export default BrainfishWidget;
