// Libraries
import _ from 'lodash';

// Supermove
import {useCallback, useState, useTrackModal} from '@supermove/hooks';

export interface ModalType {
  key: string;
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  handleToggle: () => void;
  count: number;
}

const useModal = ({
  initialIsOpen = false,
  onOpen,
  onClose,
  openDelay = 200,
  name,
  enableTracking = false,
}: {
  initialIsOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  openDelay?: number;
  name?: string;
  enableTracking?: boolean;
} = {}): ModalType => {
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const trackEvent = useTrackModal({name});

  const open = useCallback(() => {
    setIsOpen(true);
    setCount(count + 1);
    if (onOpen) {
      onOpen();
    }

    if (enableTracking) {
      trackEvent();
    }
  }, [count, enableTracking, onOpen, trackEvent]);

  const close = useCallback(() => {
    setIsOpen(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  // We need to open on the next tick so that changing the isOpen isn't batched
  // with clicking on the background overlay to close the modal (causes the modal
  // to open and close really fast).
  const handleOpen = useCallback(() => setTimeout(open, openDelay), [open, openDelay]);

  const handleClose = useCallback(() => close(), [close]);

  const handleToggle = useCallback(() => (isOpen ? close() : open()), [isOpen, close, open]);

  return {
    key: `${_.startCase(name)}_${isOpen}`,
    isOpen,
    count,
    handleOpen,
    handleClose,
    handleToggle,
  };
};

export default useModal;
