import React from 'react';

interface PageContext {
  /**
   * Currently only relevant for manager. Represents which "sub-app" of manager the page is for
   * i.e. office, mover, or customer
   */
  appNamespace?: string;

  /**
   * Name of the current page, determined by the router.
   */
  pageName?: string;
}

const PageContext = React.createContext<PageContext>({});
export default PageContext;
