// Supermove
import {useRef} from '@supermove/hooks';

const useTextInput = () => {
  // TODO(jay): Proper typing for this ref
  const ref = useRef<any>();

  // We wrap handleFocus in a setTimeout because the standard use case
  // is that the text input is not rendered until some ui action occurs.
  // The setTimeout ensures that the focus function runs after the text
  // input has been rendered.
  const handleFocus = () => {
    setTimeout(() => ref.current && ref.current.focus(), 0);
  };

  const handleBlur = () => ref.current.blur();
  const handleClear = () => ref.current.clear();
  const isFocused = () => ref.current.isFocused();

  return {
    ref,
    handleBlur,
    handleFocus,
    handleClear,
    isFocused,
  };
};

export default useTextInput;
