// Libraries
import * as Sentry from '@sentry/react';
import React from 'react';

import {Storage} from '@supermove/sdk';

import {
  AmplitudeProps,
  AmplitudeType,
  AmplitudeHandleIdentifyArgs,
  AmplitudeHandleTrackArgs,
} from './AmplitudeTypes';

class Amplitude extends React.Component<AmplitudeProps> {
  // Loaded by gatsby-plugin-amplitude in componentDidMount. If we're calling any methods before that's defined, we want to throw an error.
  // If that happens, we can use an awaited form of all these methods that resolves when the amplitude object is defined.
  protected amplitude: AmplitudeType = undefined as unknown as AmplitudeType;

  get isEnabled() {
    return !!this.amplitude;
  }

  componentDidMount() {
    // This is loaded up by the gatsby-plugin-amplitude package.
    this.amplitude = (window as unknown as {amplitude: AmplitudeType}).amplitude;

    // If the browser blocks content trackers, gatsby-plugin-amplitude fails to fetch the Amplitude
    // SDK from their CDN. However, there's a _different_ version of Amplitude in our app bundle
    // that gets loaded instead, and this other version doesn't have the getSessionId method.
    // TODO(atsu): Figure out how to use consistent versions (or just stop fetching from their CDN?)
    if (this.isEnabled && this.amplitude.getSessionId) {
      const sessionId = `${this.amplitude.getSessionId()}`;
      Storage.setItem('amplitude_session_id', sessionId);
      Sentry.setTags({
        session_id: sessionId,
      });
      Sentry.setExtras({
        'debug.sentry_session_url': `https://sentry.io/organizations/supermove/issues/?project=-1&query=session_id:${sessionId}`,
      });
    }
  }

  handleIdentify = ({
    id,
    firstName,
    lastName,
    fullName,
    email,
    role,
    organizationSlug,
    companyIdentifier,
    companySalesforceId,
  }: AmplitudeHandleIdentifyArgs) => {
    // Amplitude library isn't loaded up when in development.
    if (this.isEnabled && id) {
      this.amplitude.setUserId(id);
      this.amplitude.setUserProperties({
        'ID': id,
        'First Name': firstName,
        'Last Name': lastName,
        'Full Name': fullName,
        'Email': email,
        'Role': role,
        'Organization': organizationSlug,
        'Company': companyIdentifier,
        'Company Salesforce ID': companySalesforceId,
      });
    }
  };

  handleTrack = ({event, data}: AmplitudeHandleTrackArgs) => {
    if (this.isEnabled) {
      this.amplitude.logEvent(event, data);
    }
  };

  handleReset = () => {
    if (this.isEnabled) {
      this.amplitude.clearUserProperties();
    }
  };

  render() {
    return this.props.children({
      identify: this.handleIdentify,
      track: this.handleTrack,
      reset: this.handleReset,
    });
  }
}

export default Amplitude;
