/**
 * Used with Fuse library for speedy search results.
 */

// Supermove
import {useState} from '@supermove/hooks';
import {Fuse} from '@supermove/utils';

interface UseSearchArgs<T> {
  initialQuery: string;
  options: Fuse.IFuseOptions<unknown>;
  items: T[];
  limit: number;
}

const useSearch = <T>({initialQuery, options, items, limit}: UseSearchArgs<T>) => {
  const [query, setQuery] = useState(initialQuery);
  const fuse = new Fuse(items, options);
  const fuseResults = fuse.search(query, {limit});
  const results = query ? fuseResults.map((result) => result.item) : items;

  return {
    results,
    query,
    setQuery,
  };
};

export default useSearch;
