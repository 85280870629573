import {useModal, ModalType} from '@supermove/hooks';

export type DrawerType = ModalType;

const useDrawer = ({
  initialIsOpen = false,
  onOpen,
  onClose,
  openDelay = 0,
  name,
  enableTracking = true,
}: {
  initialIsOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  openDelay?: number;
  name?: string;
  enableTracking?: boolean;
}): DrawerType => {
  return useModal({
    initialIsOpen,
    onOpen,
    onClose,
    openDelay,
    name,
    enableTracking,
  });
};

export default useDrawer;
