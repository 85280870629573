// Libraries
import React from 'react';
// @ts-expect-error library is not typed
import ReactModal from 'react-modal';
import styled from 'styled-components';

// Supermove
import Styled from '../Styled/Styled';

const ScreenContainer = Styled.View<{alignItems: string}>`
  flex: 1;
  align-items: ${({alignItems}) => alignItems};
`;

interface SidebarV2Props extends Record<string, unknown> {
  isOpen: boolean;
  onClose: () => void;
  position?: string;
  shouldCloseOnClickOutside?: boolean;
  children: React.ReactNode;
}

const SidebarV2 = ({
  isOpen,
  onClose,
  className,
  children,
  position = 'left',
  shouldCloseOnClickOutside = true,
  ...props
}: SidebarV2Props) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      className={contentClassName}
      portalClassName={className}
      overlayClassName={overlayClassName}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={shouldCloseOnClickOutside}
      {...props}
    >
      <ScreenContainer
        pointerEvents={'box-none'}
        alignItems={position === 'left' ? 'flex-start' : 'flex-end'}
      >
        {children}
      </ScreenContainer>
    </ReactModal>
  );
};

SidebarV2.Content = styled(SidebarV2).attrs({
  suppressClassNameWarning: true,
})`
  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(74, 74, 74, 0.3);
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    pointer-events: none;
  }
  &__content * {
    pointer-events: all;
  }
`;

export default SidebarV2;
