// Libraries
import KeyboardAvoidingView from '@react-x/keyboard-avoiding-view';
import React from 'react';

import {Platform} from '@supermove/sdk';

// Components
import StatusBar from '../StatusBar';

type KeyboardViewProps = React.ComponentProps<typeof KeyboardAvoidingView>;

const KeyboardView = (props: KeyboardViewProps) => (
  <KeyboardAvoidingView
    keyboardVerticalOffset={StatusBar.currentHeight}
    behavior={Platform.select({
      ios: 'padding',
      android: undefined,
    })}
    style={{flex: 1}}
    {...props}
  />
);

export default KeyboardView;
