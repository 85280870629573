// Libraries
import React from 'react';

// Supermove
import {useHover} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Components
import Button, {ButtonProps} from './Button';

const SecondaryButton = ({children, ...props}: ButtonProps) => {
  const {isHovered, ref} = useHover();

  return (
    <Button ref={ref} color={isHovered ? colors.hover : colors.white} {...props}>
      {children}
    </Button>
  );
};

export default SecondaryButton;
