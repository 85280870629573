// Function to process a row of data and convert it to a CSV format
const processRow = (row: string[][]) => {
  let finalVal = '';
  for (let j = 0; j < row.length; j += 1) {
    // Convert the element to a string, handling null values
    let innerValue = row[j] === null ? '' : row[j].toString();
    // If the element is a Date object, convert it to a locale string
    if (row[j] instanceof Date) {
      innerValue = row[j].toLocaleString();
    }
    // Replace any double quotes with two double quotes for CSV format
    let result = innerValue.replace(/"/g, '""');
    // If the result contains special characters, enclose it in double quotes
    if (result.search(/("|,|\n)/g) >= 0) result = `"${result}"`;
    // If it's not the first element, add a comma separator
    if (j > 0) finalVal += ',';
    finalVal += result;
  }
  return `${finalVal}\n`;
};

const useExportRowsToCsv = () => {
  // Example rows that it reads, each array is a new row
  // [
  //   ["Example 1", "Example 2"],
  //   ["1", "2"],
  // ]

  const handleExport = ({filename, rows}: {filename: string; rows: string[][][]}) => {
    let csvFile = '';
    for (let i = 0; i < rows.length; i += 1) {
      csvFile += processRow(rows[i]);
    }

    // Create a Blob to handle large exports of csv
    const blob = new Blob([csvFile], {type: 'text/csv;charset=utf-8;'});
    // For Internet Explorer
    // @ts-expect-error this is on the browser
    if (window.navigator.msSaveBlob) {
      // @ts-expect-error this is on the browser
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${filename}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  return {
    handleExport,
  };
};

export default useExportRowsToCsv;
