// @ts-expect-error library is not typed
import {Query as IncrementQuery} from '@increment/components';
import React from 'react';

import {useApolloContextWithHeaders} from '@supermove/graphql';

/**
 * Wrapper around Apollo's Query component that injects additional HTTP headers
 */
const Query = ({children, context, ...props}: any) => {
  return (
    <IncrementQuery context={useApolloContextWithHeaders(context)} {...props}>
      {children}
    </IncrementQuery>
  );
};

export default Query;
