// Supermove
import {useTrackEvent} from '@supermove/hooks';

const useTrackPopover = ({name}: {name?: string}) => {
  const {trackEvent} = useTrackEvent({
    eventName: `Viewed ${name}`,
    eventType: 'Popover View',
    // TODO(sam): Add pageName when it is available in navigator
    pageName: '',
  });

  return trackEvent;
};

export default useTrackPopover;
