/**
 * Modifiers are used in conjunction with styled-components functions. Ideally
 * these would work with react hooks but can't right now because styled-components
 * functions aren't evaluated in the render function which is a requirememt for hooks.
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Libraries
import {ResponsiveType} from '@supermove/hooks';

export const withResponsive = (styles: Record<keyof ResponsiveType, React.CSSProperties>) => {
  return ({responsive}: {responsive: ResponsiveType}) => {
    const size = _.find(['large', 'desktop', 'tablet', 'mobile'], (size) => {
      if (_.get(responsive, size) && _.get(styles, size)) {
        return size;
      }
    });

    const defaultStyle = _.get(styles, 'default');
    // @ts-expect-error _.find is typed to potentially return ArrayIterator, which doesn't work for _.get. If we change to regular .find it should work
    return _.get(styles, size, defaultStyle);
  };
};
