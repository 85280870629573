// useSpreadsheet hook used to format the columns and data properly to be used in the Spreadsheet component
// Full documentation for the react-table library that requires this format https://react-table.tanstack.com/docs/overview

// Supermove
import {useMemo} from '@supermove/hooks';

/**
 * Grabs the column names to create an array of column objects to be used in the Spreadsheet component
 * @param {[Object]} data Array of row objects like [{"col1": "val 1", "col2": "val 2"}, {"col1": "val 1", "col2": "val 2"}, {"col1": "val 1", "col2": "val 2"}]
 * @param {int} columnWidth Integer value for the column width in pixels
 * @returns [Object] Array of column objects with a default columnWidth
 */
const getTableColumnsFromTableData = (data: Array<Record<string, string>>, columnWidth: number) => {
  if (!data || data.length <= 0) return [];
  const columns = [];
  for (const [key] of Object.entries(data[0])) {
    columns.push({Header: key, accessor: key, width: columnWidth});
  }
  return columns;
};

const useSpreadsheet = ({
  jsonData,
  columnWidth,
}: {
  jsonData: Array<Record<string, string>>;
  columnWidth: number;
}) => {
  const columns = getTableColumnsFromTableData(jsonData, columnWidth);
  const spreadsheetColumns = useMemo(() => columns, [columns]);
  const spreadsheetData = useMemo(() => jsonData, [jsonData]);

  return {
    spreadsheetColumns,
    spreadsheetData,
  };
};

export default useSpreadsheet;
