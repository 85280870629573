// Libraries
import React from 'react';

// Supermove
import {Environment} from '@supermove/sdk';

// App
import AnalyticsContext from '../context/AnalyticsContext';
import Amplitude from '../services/Amplitude';
import Mixpanel from '../services/Mixpanel';
import Sentry from '../services/Sentry';
import UXCam from '../services/UXCam';
import Userflow from '../services/Userflow';
import useVitally from '../services/Vitally/useVitally';

import Collector from './Collector';

const AnalyticsServices = ({children}: {children: React.ReactNode}) => {
  // Configs
  const amplitudeConfig = {apiKey: Environment.get('AMPLITUDE_API_KEY')};
  const mixpanelConfig = {token: Environment.get('MIXPANEL_TOKEN')};
  const uxcamConfig = {apiKey: Environment.get('UXCAM_API_KEY')};
  const userflowConfig = {apiKey: Environment.get('USERFLOW_KEY')};
  const sentryConfig = {}; // Initialization happens in the ErrorView component
  const vitallyConfig = {token: Environment.get('VITALLY_TOKEN'), domain: 'supermove'};

  // Hooks
  const vitally = useVitally(vitallyConfig);

  // TODO(mark): React Hooks would clean up this nesting.
  return (
    <UXCam {...uxcamConfig}>
      {(uxcam) => (
        <Mixpanel {...mixpanelConfig}>
          {(mixpanel) => (
            <Amplitude {...amplitudeConfig}>
              {(amplitude) => (
                <Userflow {...userflowConfig}>
                  {(userflow) => (
                    <Sentry {...sentryConfig}>
                      {(sentry) => (
                        <Collector
                          services={[uxcam, mixpanel, amplitude, userflow, sentry, vitally]}
                        >
                          {(analytics) => (
                            <AnalyticsContext.Provider value={analytics}>
                              {children}
                            </AnalyticsContext.Provider>
                          )}
                        </Collector>
                      )}
                    </Sentry>
                  )}
                </Userflow>
              )}
            </Amplitude>
          )}
        </Mixpanel>
      )}
    </UXCam>
  );
};

export default AnalyticsServices;
