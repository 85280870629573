// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {useRef, useState, useTrackPopover} from '@supermove/hooks';

export interface PopoverType {
  key: string;
  ref: React.MutableRefObject<any>;
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  handleToggle: () => void;
}

const usePopover = ({
  name,
  enableTracking = false,
}: {name?: string; enableTracking?: boolean} = {}): PopoverType => {
  const ref = useRef();
  const [isOpen, setVisible] = useState(false);
  const trackEvent = useTrackPopover({name});
  const handleOpen = () => {
    setVisible(true);
    // note: this is a hack to deal with the fact that handleOpen gets called twice in many cases;
    // once when we want to open the popover, and once inside the popover after it has been opened.
    if (!isOpen && enableTracking) {
      trackEvent();
    }
  };
  const handleClose = () => setVisible(false);
  const handleToggle = () => {
    setVisible(!isOpen);
    if (enableTracking && !isOpen) {
      trackEvent();
    }
  };

  return {
    key: `${_.startCase(name)}_${isOpen}`,
    ref,
    isOpen,
    handleOpen,
    handleClose,
    handleToggle,
  };
};

export default usePopover;
