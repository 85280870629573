const HTML = {
  fixRichTextEditor: (html: string) => {
    const fullTag = new RegExp('<p><br></p>', 'g'); // eslint-disable-line
    const openTag = new RegExp(`<p>`, 'g'); // eslint-disable-line
    const closeTag = new RegExp(`</p>`, 'g'); // eslint-disable-line
    return html.replace(fullTag, '<br>').replace(openTag, '').replace(closeTag, '<br>');
  },
  removeElement: (html: string, element: string) => {
    const openTag = new RegExp(`<${element}>`, 'g'); // eslint-disable-line
    const closeTag = new RegExp(`</${element}>`, 'g'); // eslint-disable-line
    return html.replace(openTag, '').replace(closeTag, '');
  },
};

export default HTML;
