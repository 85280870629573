// Libraries
import React from 'react';

// Supermove
import {AnalyticsServices, useAnalytics} from '@supermove/analytics';
import {OfflineOverlay} from '@supermove/components';
import {useInternet, useMountEffect} from '@supermove/hooks';
import {Environment} from '@supermove/sdk';
import {GlobalStyle} from '@supermove/styles';

const GlobalAnalytics = ({
  name,
  version,
  buildNumber,
}: {
  name: string;
  version: string;
  buildNumber: number;
}) => {
  const analytics = useAnalytics();

  useMountEffect(() => {
    if (analytics.setGlobalEventData) {
      analytics.setGlobalEventData({name, version, buildNumber});
    }
  });

  return null;
};

const GlobalAppWrapper = ({
  showOfflineOverlay,
  name,
  version,
  buildNumber,
  children,
}: {
  showOfflineOverlay: boolean;
  name: string;
  version: string;
  buildNumber: number;
  children: React.ReactNode;
}) => {
  const {hasFetched, hasConnected, isConnected} = useInternet();

  useMountEffect(() => {
    console.log(
      `[App]: Starting "${name} v${version}" build ${buildNumber} in ` +
        `environment: "${Environment.getAppEnv()}".`,
    );
  });

  if (!hasFetched) {
    return null;
  }

  return (
    <React.Fragment>
      <GlobalStyle />
      {(hasConnected || Environment.isLocal()) && (
        <AnalyticsServices>
          <GlobalAnalytics name={name} version={version} buildNumber={buildNumber} />
          {children}
        </AnalyticsServices>
      )}
      {showOfflineOverlay && !isConnected && !Environment.isLocal() && <OfflineOverlay />}
    </React.Fragment>
  );
};

export default GlobalAppWrapper;
