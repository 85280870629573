// Libraries
import React from 'react';

// Supermove
import {Typography} from '@supermove/styles';

// Components
import Styled from '../Styled';

const Container = Styled.View<{color: string}>`
  align-items: center;
  justify-content: center;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  border-width: 2px;
  border-color: ${(props) => props.color};
  border-radius: 3px;
`;

const Text = Styled.Text<{color: string}>`
  ${Typography.Label2}
  color: ${(props) => props.color};
  text-transform: uppercase;
`;

interface BadgeProps {
  color: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  textStyle?: React.CSSProperties;
}

const Badge = ({color, children, style, textStyle}: BadgeProps) => (
  <Container color={color} style={style}>
    <Text color={color} style={textStyle}>
      {children}
    </Text>
  </Container>
);

export default Badge;
