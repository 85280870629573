import {
  TypedDocumentNode,
  useQuery,
  useMutation,
  QueryHookOptions,
  QueryResult,
  MutationHookOptions,
  MutationTuple,
  DefaultContext,
  OperationVariables,
  DocumentNode,
} from '@apollo/client';
import {useContext} from 'react';

import {PageContext} from '@supermove/analytics';

/**
 * Adds additional HTTP headers to the given Apollo Context object.
 *
 * The 'headers' field is used by the middleware in ./apollo.js
 */
export const useTypedApolloContextWithHeaders = <
  TContext extends DefaultContext & {headers?: Record<string, string>} = DefaultContext & {
    headers?: Record<string, string>;
  },
>(
  context?: TContext,
): TContext => {
  if (!context) {
    context = {} as TContext;
  }

  const {pageName, appNamespace} = useContext(PageContext);
  context.headers = {
    ...context.headers,
    ...(appNamespace ? {'x-supermove-app-namespace': appNamespace} : {}),
    ...(pageName ? {'x-supermove-page-name': pageName} : {}),
  };
  return context;
};

/**
 * Wrapper around Apollo's useQuery hook that injects additional HTTP headers
 */
export const useTypedQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> => {
  if (!options) {
    options = {};
  }
  options.context = useTypedApolloContextWithHeaders(options.context);
  return useQuery(query, options);
};

/**
 * Wrapper around Apollo's useMutation hook that injects additional HTTP headers
 */
export const useTypedMutation = <
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables, TContext>,
): MutationTuple<TData, TVariables, TContext> => {
  if (!options) {
    options = {};
  }
  options.context = useTypedApolloContextWithHeaders(options.context);
  return useMutation(mutation, options);
};
