// Libraries
import React from 'react';
// @ts-expect-error library is not typed
import SwipeableViews from 'react-swipeable-views';

// Supermove
import {colors} from '@supermove/styles';

import Styled from '../Styled';

// Components
import {TabsProps} from './TabsTypes';
import TabBar from './components/TabBar';
import TabBarTab from './components/TabBarTab';

const Container = Styled.View`
`;

const Slide = Styled.View`
  flex: 1;
`;

const TabUnderline = Styled.View`
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: ${colors.blue.interactive}
  bottom: 0px;
`;

const TabSlideViewer = ({
  tabs,
  disabledSwipe,
  containerStyle,
  children,
}: {
  tabs: any;
  disabledSwipe?: boolean;
  containerStyle?: any;
  children: React.ReactNode;
}) => {
  return (
    <SwipeableViews
      index={tabs.selectedIndex}
      style={{flex: 1}}
      onChangeIndex={tabs.handleChangeIndex}
      containerStyle={{width: '100%', ...(containerStyle || {})}}
      disabled={disabledSwipe}
      enableMouseEvents
    >
      {children}
    </SwipeableViews>
  );
};

const Tabs = ({
  children,
  style,
  tabBarStyle,
  scrollEnabled = true,
  showFullWidthBar = true,
  fullWidthBarColor = colors.gray.border,
  fullWidthBarStyle,
  tabs,
  TabComponent,
  TabBarFooter,
  disabledSwipe = false,
  showsHorizontalScrollIndicator,
  scrollViewRef,
}: TabsProps) => {
  return (
    <Container style={style}>
      <TabBar
        selectedIndex={tabs.selectedIndex}
        handleChangeIndex={tabs.handleChangeIndex}
        TabComponent={TabComponent}
        style={tabBarStyle}
        scrollEnabled={scrollEnabled}
        showFullWidthBar={showFullWidthBar}
        fullWidthBarColor={fullWidthBarColor}
        fullWidthBarStyle={fullWidthBarStyle}
        showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
        scrollViewRef={scrollViewRef}
      >
        {children}
      </TabBar>
      {TabBarFooter && <TabBarFooter />}
      <TabSlideViewer tabs={tabs} disabledSwipe={disabledSwipe}>
        {children}
      </TabSlideViewer>
    </Container>
  );
};

Tabs.Slide = Slide;
Tabs.Tab = TabBarTab;
Tabs.TabUnderline = TabUnderline;
Tabs.TabBar = TabBar;
Tabs.TabContainer = TabBarTab.Container;
// @ts-expect-error this doesn't actually exist
Tabs.TabContainerScrollEnabled = TabBarTab.ContainerScrollEnabled;
Tabs.TabText = TabBarTab.Text;
Tabs.SlideViewer = TabSlideViewer;

export default Tabs;
