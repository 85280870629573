// Libraries
import _ from 'lodash';
import React from 'react';

// Components
import {Form, FormValues} from '@supermove/hooks';

import ValidationError from './ValidationError';

interface FieldProps<T extends FormValues> {
  as: React.ComponentType<any>;
  name: string;
  form: Form<T>;
}

const Field = <T extends FormValues>({as: InputComponent, name, form, ...props}: FieldProps<T>) => (
  <React.Fragment>
    <InputComponent
      {...props}
      name={name}
      value={_.get(form.values, name)}
      onChangeText={(text: string) => form.setFieldValue(name, text)}
      onBlur={() => form.setFieldTouched(name, true)}
    />
    <ValidationError name={name} touched={form.touched} errors={form.errors} />
  </React.Fragment>
);

export default Field;
