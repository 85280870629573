// Libraries
import _ from 'lodash';
import React from 'react';
import toast from 'react-hot-toast';

interface UseHandleMutationAlertProps {
  ToastComponent: React.FC<{
    message: string;
    handleDismiss: () => void;
    handleRemove: () => void;
  }>;
}

const useHandleMutationAlertErrors = ({ToastComponent}: UseHandleMutationAlertProps) => {
  const handleMutationAlertErrors = ({
    errors,
  }: {
    errors: Array<{field: string; message: string}>;
  }) => {
    const alertErrors = _.filter(errors, (error) => {
      return error.field === 'alert';
    });
    return alertErrors.forEach((error) => {
      toast((toastElement) => (
        <ToastComponent
          message={error.message}
          handleDismiss={() => toast.dismiss(toastElement.id)}
          handleRemove={() => toast.remove(toastElement.id)}
        />
      ));
    });
  };
  return {
    handleMutationAlertErrors,
  };
};

export default useHandleMutationAlertErrors;
