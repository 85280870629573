// Libraries
import ActivityIndicator from '@react-x/activity-indicator';
import Image from '@react-x/image';
import Text from '@react-x/text';
import {TouchableOpacity} from '@react-x/touchable';
import View from '@react-x/view';

// App
// TODO(mark): This loads dynamically depending on what is in the app's styles package.
import {typography} from '@supermove/styles';

// Components
import Button from './Button';
import ButtonV2 from './ButtonV2';
import LoadingButton from './LoadingButton';
import SecondaryButton from './SecondaryButton';
import StatefulButton from './StatefulButton';
import Styled from './Styled';
import TextInput from './TextInput';

// Primitives
Styled.Image = Styled(Image);
Styled.Text = Styled(Text);
Styled.View = Styled(View);

// Additional
Styled.Loading = Styled(ActivityIndicator);
// need to do "as" type because the rest of TextInput is declared below
Styled.TextInput = Styled(TextInput) as (typeof Styled)['TextInput'];
Styled.Touchable = Styled(TouchableOpacity);

// Buttons
Styled.Button = Styled(Button);
Styled.ButtonV2 = Styled(ButtonV2);
Styled.SecondaryButton = Styled(SecondaryButton);
Styled.LoadingButton = Styled(LoadingButton);
Styled.StatefulButton = Styled(StatefulButton);

// Headings
Styled.H1 = Styled(Styled.Text`${(props) => typography.h1(props)}`);
Styled.H2 = Styled(Styled.Text`${(props) => typography.h2(props)}`);
Styled.H3 = Styled(Styled.Text`${(props) => typography.h3(props)}`);
Styled.H4 = Styled(Styled.Text`${(props) => typography.h4(props)}`);
Styled.H5 = Styled(Styled.Text`${(props) => typography.h5(props)}`);
Styled.H6 = Styled(Styled.Text`${(props) => typography.h6(props)}`);
Styled.H7 = Styled(Styled.Text`${(props) => typography.h7(props)}`);
Styled.H8 = Styled(Styled.Text`${(props) => typography.h8(props)}`);

// TextInputs
Styled.TextInput.Plain = Styled(TextInput.Plain);
Styled.TextInput.H1 = Styled(TextInput.H1);
Styled.TextInput.H2 = Styled(TextInput.H2);
Styled.TextInput.H3 = Styled(TextInput.H3);
Styled.TextInput.H4 = Styled(TextInput.H4);
Styled.TextInput.H5 = Styled(TextInput.H5);
Styled.TextInput.H6 = Styled(TextInput.H6);
Styled.TextInput.H7 = Styled(TextInput.H7);

export default Styled;
