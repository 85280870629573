// Libraries
import React from 'react';

import {colors} from '@supermove/styles';

import {CurrencyInputProps} from './CurrencyInputTypes';
import CurrencyPicker from './CurrencyPicker';

const CurrencyInput = ({
  disabled,
  shouldHideCentsIfZero = false,
  keyboardType = 'default',
  component,
  name,
  placeholder,
  value,
  onChange,
  onChangeText = () => {},
  onBlur = () => {},
  setFieldValue,
  setFieldTouched,
  style,
  options,
  ...props
}: CurrencyInputProps) => (
  <CurrencyPicker
    {...props}
    // @ts-expect-error This should have types for field input, but field input is in shared
    name={name}
    disabled={disabled}
    shouldHideCentsIfZero={shouldHideCentsIfZero}
    keyboardType={keyboardType}
    placeholder={placeholder}
    value={value}
    component={component}
    onBlur={(event) => {
      onBlur(event);
      setFieldTouched(name, true);
    }}
    onChangeText={(value) => {
      setFieldValue(name, value);
      onChangeText(value);
    }}
    style={{
      color: colors.gray.primary,
      backgroundColor: disabled ? colors.gray.disabled : colors.white,
      ...style,
    }}
    options={options}
  />
);

export default CurrencyInput;
