// Libraries
import moment from 'moment';
import React from 'react';
// @ts-expect-error library is not typed
import BigCalendar from 'react-big-calendar';

// Assets
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css';

// const getLocalizer = ({isSundayFirst}) => {
//   if (!isSundayFirst) {
//     moment.updateLocale(
//       'us',
//       {
//         week: {
//           dow: 1,
//           doy: 1,
//         },
//       }
//     );
//   }
//   return BigCalendar.momentLocalizer(moment);
// };

const localizer = BigCalendar.momentLocalizer(moment);

interface CalendarProps {
  hasToolbar?: boolean;
  isSundayFirst?: boolean;
  date: string;
  events?: any[];
  view?: string;
  views?: string[];
  eventPropGetter?: null | (() => unknown);
  onNavigate?: () => void;
  onView?: () => void;
  dateCellWrapperComponent: React.ReactNode;
  dateHeaderComponent?: React.ReactNode;
  eventComponent?: React.ReactNode;
  toolbarComponent?: React.ReactNode;
}

const Calendar = ({
  hasToolbar = true,
  date,
  events = [],
  view = 'month',
  views = ['month'],
  eventPropGetter = null,
  onNavigate = () => {},
  onView = () => {},
  dateCellWrapperComponent,
  dateHeaderComponent = null,
  eventComponent = null,
  toolbarComponent,
}: CalendarProps) => (
  <BigCalendar
    components={{
      dateCellWrapper: dateCellWrapperComponent,
      dateHeader: dateHeaderComponent,
      event: eventComponent,
      toolbar: toolbarComponent,
    }}
    date={moment(date).toDate()}
    drilldownView={null} // Disables drilling down into a single day.
    events={events}
    localizer={localizer}
    toolbar={hasToolbar}
    view={view}
    views={views}
    eventPropGetter={eventPropGetter}
    onNavigate={onNavigate}
    onView={onView}
  />
);

export default Calendar;
