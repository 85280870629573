import {useModal} from '@supermove/hooks';

interface UseSheetProps {
  initialIsOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  openDelay?: number;
  name: string;
  enableTracking?: boolean;
}

const useSheet = ({
  initialIsOpen = false,
  onOpen,
  onClose,
  openDelay = 0,
  name,
  enableTracking = true,
}: UseSheetProps) => {
  return useModal({
    initialIsOpen,
    onOpen,
    onClose,
    openDelay,
    name,
    enableTracking,
  });
};

export default useSheet;
