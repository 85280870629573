// Libraries
import React from 'react';
import ReactPlayer from 'react-player';

interface VideoPlayerProps {
  controls: boolean;
  url: string;
  width?: number | string;
  height?: number | string;
  style?: React.CSSProperties;
  onStart?: () => void;
  onPlay?: () => void;
  onEnd?: () => void;
  isHLS?: boolean;
}

const VideoPlayer = ({
  controls,
  url,
  width,
  height,
  style,
  onStart,
  onPlay,
  onEnd,
  isHLS,
}: VideoPlayerProps) => {
  //
  // HACK(mark): We want to forceHLS on Google Chrome only. Everything seems to work with forceHLS
  // on EXCEPT Safari on an actual iOS device. However, in the simulated chrome environment where
  // we select "iPhone 6/7/8" as the device, the browser will think it's Safari on an actual iOS
  // device and it'll fail to play the video. This is bad for our web demo environment that we
  // use for sales.
  //
  // To get around this, we will check the vendor which is able to detect if we're on chrome using
  // the simulated environment. Since it's okay to always force HLS on all Google browsers (Chrome),
  // this seems to work best.
  //
  const isChrome = window.navigator.vendor === 'Google Inc.';

  return (
    <ReactPlayer
      controls={controls}
      config={{
        file: {
          forceHLS: isHLS && isChrome,
        },
      }}
      url={url}
      width={width}
      height={height}
      style={style}
      onStart={onStart}
      onPlay={onPlay}
      onEnded={onEnd}
    />
  );
};

export default VideoPlayer;
