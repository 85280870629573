import {PermissionsType} from './PermissionsType';

const Permissions: PermissionsType = {
  LOCATION: 'location',

  RESULTS: {
    AUTHORIZED: 'authorized',
    DENIED: 'denied',
    RESTRICTED: 'restricted',
    UNDETERMINED: 'undetermined',
  },

  check: async (type) => {
    // TODO(mark): Implement for web.
    return Promise.resolve(Permissions.RESULTS.AUTHORIZED);
  },

  request: async (type) => {
    // TODO(mark): Implement for web.
    return Promise.resolve(Permissions.RESULTS.AUTHORIZED);
  },

  requestAndCheckIsCameraGranted: async () => {
    console.error('requestAndCheckIsCameraGranted is not implemented for web');
    throw new Error('requestAndCheckIsCameraGranted is not implemented for web');
  },

  requestAndCheckIsImagesGranted: async () => {
    console.error('requestAndCheckIsImagesGranted is not implemented for web');
    throw new Error('requestAndCheckIsImagesGranted is not implemented for web');
  },

  ANDROID: null as any, // We want this to error if accessed outside of android

  handleAndroidCheckLocationTrackingPermission: async ({handleGetPermission}) => {
    console.error('handleAndroidCheckLocationTrackingPermission is not implemented for web');
  },

  handleAndroidLocationTrackingPermission: async () => {
    console.error('handleAndroidLocationTrackingPermission is not implemented for web');
    throw new Error('handleAndroidLocationTrackingPermission is not implemented for web');
  },
};

export default Permissions;
