// Libraries
import React from 'react';
import toast from 'react-hot-toast';

// Supermove
import {HandleToastArgs, UseToastType} from '@supermove/hooks/src/ui/useToast/UseToastType';

const useToast: UseToastType = ({
  ToastComponent,
  message,
  actionText,
  handleAction,
  isCloseable,
  duration,
}) => {
  const handleToast = ({actionPayload = {}, message: dynamicMessage}: HandleToastArgs = {}) => {
    toast(
      (toastElement) => (
        <ToastComponent
          message={dynamicMessage || message}
          actionText={actionText}
          handleAction={() => {
            if (handleAction) {
              handleAction(actionPayload);
            }
          }}
          handleDismiss={() => toast.dismiss(toastElement.id)}
          handleRemove={() => toast.remove(toastElement.id)}
          isCloseable={isCloseable}
        />
      ),
      {
        duration: duration || 5000, // Takes milliseconds or Infinity
      },
    );
  };

  return {
    handleToast,
  };
};

export default useToast;
