// Libraries
import React from 'react';

// Supermove
import {Lifecycle} from '@supermove/components';
import {UserModel} from '@supermove/models';

// Components
import AnalyticsContext from '../context/AnalyticsContext';

interface SkipIdentifyProps {
  viewer: UserModel;
  children: React.ReactNode;
}

const SkipIdentify = ({viewer, children}: SkipIdentifyProps) => {
  return (
    <AnalyticsContext.Consumer>
      {(analytics) => <Lifecycle onMount={() => analytics.skipIdentify?.()} children={children} />}
    </AnalyticsContext.Consumer>
  );
};

export default SkipIdentify;
