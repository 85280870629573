// Libraries
import {useEffect, EffectCallback} from 'react';

/**
 * Passing an empty array as the second argument ensures that this effect is
 * only run on the first render.
 *
 * See: https://reactjs.org/docs/hooks-effect.html#tip-optimizing-performance-by-skipping-effects
 *
 */
const useMountEffect = (effect: EffectCallback): void => {
  useEffect(effect, []);
};

export default useMountEffect;
