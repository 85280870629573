// Supermove
import {useState, useRef} from '@supermove/hooks';

/**
 * This hook should be used with the DragAndDropList component.
 * It provides the stateful logic to disable a drag while
 * onReorder is in progress.
 */
const useDragAndDrop = () => {
  const [isReordering, setIsReordering] = useState(false);
  const handleReorderStart = () => setIsReordering(true);
  const handleReorderEnd = () => setIsReordering(false);

  const ref = useRef<HTMLElement>();
  const handleScrollTo = ({x, y}: {x: number; y: number}) => {
    if (ref.current) {
      ref.current.scrollTop = y;
      ref.current.scrollLeft = x;
    }
  };
  const handleScrollToTop = () => {
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  };
  const handleScrollToEnd = () => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  };

  return {
    isReordering,
    handleReorderStart,
    handleReorderEnd,
    ref,
    handleScrollTo,
    handleScrollToTop,
    handleScrollToEnd,
  };
};

export default useDragAndDrop;
