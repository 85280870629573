// Libraries
import EmojiPicker from 'emoji-picker-react';
import React from 'react';

// Supermove
import {Popover, Styled} from '@supermove/components';
import {PopoverType, usePopover, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// Importing CSS to override global css
import './EmojisPickerGlobalDisplayStyle.css';

const Container = Styled.View`
`;

const EmojisPickerButton = Styled.ButtonV2`
  width: 45px;
  height: 36px;
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.tertiary};
  border-radius: 4px;
  padding-top: 2px;
  justify-content: center;
  align-items: center;
`;

const EmojisPickerText = Styled.Text`
  ${Typography.PageHeading}
`;

const EmojisPickerPopover = ({
  emojisPickerPopover,
  setPickedEmoji,
  onChangeValue,
}: {
  emojisPickerPopover: PopoverType;
  setPickedEmoji: (emoji: string) => void;
  onChangeValue: (emoji: string) => void;
}) => {
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      // @ts-expect-error doesn't expect a boolean
      key={emojisPickerPopover.isOpen}
      isOpen={emojisPickerPopover.isOpen}
      handleOpen={emojisPickerPopover.handleOpen}
      handleClose={emojisPickerPopover.handleClose}
      reference={emojisPickerPopover.ref}
      offset={[0, 4]}
      isClosableOutside
    >
      <EmojiPicker
        onEmojiClick={(emojiData) => {
          setPickedEmoji(emojiData.emoji);
          emojisPickerPopover.handleClose();
          onChangeValue(emojiData.emoji);
        }}
        autoFocusSearch={false}
      />
    </Popover>
  );
};

interface EmojiPickerProps {
  containerStyle?: object;
  defaultEmoji?: string;
  buttonStyle?: object;
  emojiStyle?: object;
  onChangeValue?: (emoji: string) => void;
}

const EmojisPicker = ({
  containerStyle,
  defaultEmoji = '🚛',
  buttonStyle,
  emojiStyle,
  onChangeValue = () => {},
}: EmojiPickerProps) => {
  const emojisPickerPopover = usePopover({name: 'Emojis Picker Popover', enableTracking: true});
  const [pickedEmoji, setPickedEmoji] = useState(defaultEmoji);

  return (
    <Container style={containerStyle}>
      <Popover.Content innerRef={emojisPickerPopover.ref}>
        <EmojisPickerButton style={buttonStyle} onPress={emojisPickerPopover.handleOpen}>
          <EmojisPickerText style={emojiStyle}>{pickedEmoji}</EmojisPickerText>
        </EmojisPickerButton>
      </Popover.Content>
      <EmojisPickerPopover
        emojisPickerPopover={emojisPickerPopover}
        setPickedEmoji={setPickedEmoji}
        onChangeValue={onChangeValue}
      />
    </Container>
  );
};

export default EmojisPicker;
