// Libraries

// App
import {useCallback, useEffect} from '@supermove/hooks';
import {invariant} from '@supermove/utils';

import {MutationResponse, MutationError} from './types';

type Args<Results extends Record<string, any>> = {
  data: MutationResponse<Results>;
  onSuccess?: (results: Results) => void;
  onError?: (errors: MutationError[]) => void;
};

const useMutationCallbacks = <Results extends Record<string, any> = Record<string, any>>({
  data,
  onSuccess,
  onError,
}: Args<Results>): void => {
  const onSuccessCallback = useCallback(onSuccess as (results: Results) => void, [data]);
  const onErrorCallback = useCallback(onError as (errors: MutationError[]) => void, [data]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const {response} = data;

    invariant(
      response,
      `Invalid response. Received: ${JSON.stringify(data)}. Make sure the ` +
        `mutation response is correctly aliased to 'response'.`,
    );

    // Parse the response data into 'errors' and 'response'.
    const {errors, ...rest} = response;

    if (errors) {
      onErrorCallback(errors);
    } else {
      onSuccessCallback(rest as any); // Should be `as Results` but eslint says Results is undefined
    }
  }, [data, onSuccessCallback, onErrorCallback]);
};

export default useMutationCallbacks;
