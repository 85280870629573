// Libraries
import ReactTextInput from '@react-x/text-input';

// Supermove
import {Platform} from '@supermove/sdk';
import {colors, textInputStyle, textInputValueStyle, typography} from '@supermove/styles';

// App
import Styled from './Styled';

export type TextInputProps = React.ComponentProps<typeof ReactTextInput> & {
  autoComplete?: string;
  editable?: boolean;
  placeholderTextColor?: string;
};

export type TextInputType = React.ComponentType<TextInputProps> & {
  Plain: React.ComponentType<TextInputProps>;
  H1: React.ComponentType<TextInputProps>;
  H2: React.ComponentType<TextInputProps>;
  H3: React.ComponentType<TextInputProps>;
  H4: React.ComponentType<TextInputProps>;
  H5: React.ComponentType<TextInputProps>;
  H6: React.ComponentType<TextInputProps>;
  H7: React.ComponentType<TextInputProps>;
};

// TextInputs
const TextInput = Styled(ReactTextInput)`
  ${textInputStyle}
  ${textInputValueStyle}
` as unknown as TextInputType;

TextInput.defaultProps = {
  autoComplete: Platform.select({
    ios: 'off',
    android: 'off',

    // NOTE(mark): Setting this is a hack that allows autoComplete to be disabled on Chrome.
    // However, autoFill is still active on some instances of Chrome.
    web: 'new-password',
  }),
  editable: true,
  placeholderTextColor: colors.gray.tertiary,
} as TextInputProps;

TextInput.Plain = Styled(TextInput)`
`;

TextInput.H1 = Styled(TextInput)`
  ${(props) => typography.h1(props)}
  ${textInputStyle}
  ${textInputValueStyle}
`;

TextInput.H2 = Styled(TextInput)`
  ${(props) => typography.h2(props)}
  ${textInputStyle}
  ${textInputValueStyle}
`;

TextInput.H3 = Styled(TextInput)`
  ${(props) => typography.h3(props)}
  ${textInputStyle}
  ${textInputValueStyle}
`;

TextInput.H4 = Styled(TextInput)`
  ${(props) => typography.h4(props)}
  ${textInputStyle}
  ${textInputValueStyle}
`;

TextInput.H5 = Styled(TextInput)`
  ${(props) => typography.h5(props)}
  ${textInputStyle}
  ${textInputValueStyle}
`;

TextInput.H6 = Styled(TextInput)`
  ${(props) => typography.h6(props)}
  ${textInputStyle}
  ${textInputValueStyle}
`;

TextInput.H7 = Styled(TextInput)`
  ${(props) => typography.h7(props)}
  ${textInputStyle}
  ${textInputValueStyle}
`;

export default TextInput;
