// Assets
import '@supermove/styles/css/Animation.css';
import colors from './colors';

export default {
  pulse: `
    animation-keyframes: pulse;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
    animation-iteration-count: infinite;
  `,
  // Requires component to take an inline 'color' prop
  shineWave: ({color}: {color: string}) => `
    animation-keyframes: slidingGradient;
    animation-duration: 3.0s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    background-color: ${color};
    background-image: linear-gradient(110deg, ${color} 12%, ${colors.alpha(
      colors.white,
      0.8,
    )} 50%, ${color} 88%);
    background-size: 200% 100%;
  `,
};
