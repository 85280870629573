// Libraries
import moment from 'moment';
import React from 'react';

import {TimePickerProps} from './TimePickerTypes';

class TimeInput extends React.Component<TimePickerProps> {
  getMatches = (string: string, regex: RegExp) => {
    return string.match(regex) || [];
  };

  getIsAM = (time: moment.Moment, alpha: string) => {
    if (alpha.includes('a')) {
      return true;
    }

    if (alpha.includes('p')) {
      return false;
    }

    // User didn't enter explicit AM or PM so we guess based on a range.
    return this.props.isAssumedAM?.(time);
  };

  getTime = (digits: string) => {
    switch (digits.length) {
      case 1:
        return moment(digits, 'h');
      case 2:
        return moment(digits, 'hh');
      case 3:
        return moment(digits, 'hmm');
      case 4:
        return moment(digits, 'hhmm');
      default:
        // Render 'Invalid Date' so the user knows it's wrong.
        return moment('invalid date', 'hhmm');
    }
  };

  formatValue = (value: string) => {
    const digits = this.getMatches(value, /\d+/g).join('');
    const alpha = this.getMatches(value, /[a-zA-Z]+/g)
      .join('')
      .toLowerCase();

    if (value.length === 0 || digits.length === 0) {
      // Clear the input.
      return '';
    }

    const time = this.getTime(digits);
    const isAM = this.getIsAM(time, alpha);
    const format = 'hh:mm A';

    if (!time.isValid()) {
      return this.props.invalidTimeValue;
    } else if (isAM && time.hours() === 12) {
      // User entered in '12am' and we should subtract 12 hours.
      return time.subtract(12, 'hours').format(format);
    } else if (isAM || time.hours() >= 12) {
      // 1-11am and 12pm onward should not get adjusted.
      return time.format(format);
    } else {
      // 1-11pm should get 12 hours added to become 13-23 hours.
      return time.add(12, 'hours').format(format);
    }
  };

  handleChange = (event: any) => {
    this.props.onChange(event.target.value);
  };

  handleBlur = (event: any) => {
    this.props.onChange(this.formatValue(this.props.value));
    this.props.handleBlur && this.props.handleBlur(event);
  };

  render() {
    const {
      component: InputComponent = 'input',
      value,
      isAssumedAM,
      invalidTimeValue,
      initialValue,
      roundMinutes, // Remove from 'props' to avoid passing down to the InputComponent.
      ...props
    } = this.props;

    return (
      <InputComponent
        {...props}
        value={value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
      />
    );
  }
}

// @ts-expect-error assigning to class doesn't work well
TimeInput.defaultProps = {
  isAssumedAM: (time: moment.Moment) => time.hour() >= 7 && time.hour() < 12,
  invalidTimeValue: 'Invalid Time',
  roundMinutes: 1,
};

export default TimeInput;
