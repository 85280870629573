const Collection = {
  mapWith: <T, M>(
    array: T[],
    mapper: (item: T, index: number, info: {isFirst: boolean; isLast: boolean}) => M[],
  ) => {
    return array.map((item, index) =>
      mapper(item, index, {
        isFirst: index === 0,
        isLast: index === array.length - 1,
      }),
    );
  },
};

export default Collection;
