// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ErrorView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {UserModel} from '@supermove/models';
import {Platform, Environment} from '@supermove/sdk';

const getUserInfo = ({viewer}: {viewer?: UserModel}) => {
  return {
    // Sentry-specific keys
    id: _.get(viewer, 'id', ''),
    email: _.get(viewer, 'email', ''),
    username: _.get(viewer, 'fullName', ''),

    // Custom
    ID: _.get(viewer, 'id', ''),
    Name: _.get(viewer, 'fullName', ''),
    Email: _.get(viewer, 'email', ''),
    Phone: _.get(viewer, 'phoneNumber', ''),
    Role: _.get(viewer, 'role', ''),
    Organization: _.get(viewer, 'organization.slug', ''),
    ViewingOrganization: _.get(viewer, 'viewingOrganization.slug', ''),
  };
};

const getPlatform = () => {
  return Platform.select({
    android: 'Android',
    ios: 'iOS',
    web: 'Web',
    default: 'Unknown',
  });
};

const getExtraInfo = ({
  isAuthenticated,
  version,
  buildNumber,
}: {
  isAuthenticated?: boolean;
  version: string;
  buildNumber: number;
}) => {
  return {
    'Is Authenticated': isAuthenticated,
    'App Version': version,
    'App Build': buildNumber,
    'App Name': Environment.getAppName(),
    'App Platform': getPlatform(),
  };
};

const getTags = ({
  isAuthenticated,
  viewer,
  version,
  buildNumber,
}: {
  isAuthenticated?: boolean;
  viewer?: UserModel;
  version: string;
  buildNumber: number;
}) => {
  return {
    // App info
    app_build: buildNumber,
    app_name: Environment.getAppName(),
    app_platform: getPlatform(),
    app_version: version,

    // User info
    user_email: _.get(viewer, 'email', ''),
    user_id: _.get(viewer, 'id', ''),
    user_is_authenticated: isAuthenticated,
    user_name: _.get(viewer, 'fullName', ''),
    user_phone_number: _.get(viewer, 'phoneNumber', ''),
    user_role: _.get(viewer, 'role', ''),
    user_organization: _.get(viewer, 'organization.slug', ''),
    user_viewing_organization: _.get(viewer, 'viewingOrganization.slug', ''),
  };
};

const SupermoveErrorView = ({
  version,
  buildNumber,
  onError,
  isAuthenticated,
  viewer,
  children,
}: {
  version: string;
  buildNumber: number;
  onError: (error: Error) => void;
  isAuthenticated?: boolean;
  viewer?: UserModel;
  children: React.ReactNode;
}) => {
  return (
    <ErrorView
      user={getUserInfo({viewer})}
      extra={getExtraInfo({isAuthenticated, version, buildNumber})}
      tags={getTags({isAuthenticated, viewer, version, buildNumber})}
      onError={onError}
    >
      {children}
    </ErrorView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SupermoveErrorView.fragment = gql`
  fragment SupermoveErrorView on User {
    id
    fullName
    email
    phoneNumber
    role
    organization {
      id
      slug
    }
    viewingOrganization {
      id
      slug
    }
  }
`;

export default SupermoveErrorView;
