// Libraries
import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  DefaultOptions,
  InMemoryCache,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {ErrorResponse, onError as onGraphQLError} from '@apollo/client/link/error';
import {RetryLink} from '@apollo/client/link/retry';
import {RestLink} from 'apollo-link-rest';
import {createUploadLink} from 'apollo-upload-client';
import merge from 'lodash.merge';

const RestContext = {
  useRest: true,
};

const restLink = new RestLink({
  uri: '', // Empty uri to silence console warnings - @rest directives should use the 'endpoint' field
  endpoints: {
    static: '/',
  },
});

const createErrorMiddleware = ({
  onError,
}: {
  onError: (options: ErrorResponse) => void;
}): ApolloLink => {
  return onGraphQLError((options) => onError(options)) as unknown as ApolloLink;
};

const createRetryMiddleware = ({delay, attempts}: RetryLink.Options) => {
  return new RetryLink({delay, attempts});
};

const createGraphQLMiddleware = ({uri}: {uri: string}) => {
  return ApolloLink.split(
    (operation) => operation.getContext().useRest,
    restLink as unknown as ApolloLink,
    createUploadLink({uri}) as unknown as ApolloLink,
  );
};

type GetHeadersResponse = Record<string, string | boolean | undefined | null>;

const createHeadersMiddleware = ({
  getHeaders,
}: {
  getHeaders: () => GetHeadersResponse | Promise<GetHeadersResponse>;
}) => {
  return setContext(async (request, context) => {
    const headers = await getHeaders();
    return {
      headers: {
        ...(context.headers || {}),
        ...(request as any).headers,
        ...headers,
      },
    };
  });
};

const createAuthenticationMiddleware = ({getToken}: {getToken: () => Promise<string | null>}) => {
  return createHeadersMiddleware({
    getHeaders: async () => {
      const token = await getToken();
      return {
        Authorization: token,
      };
    },
  });
};

const DEFAULT_OPTIONS = {
  watchQuery: {
    fetchPolicy: 'cache-first',
  },
};

const typePolicies = {
  CalendarDay: {
    merge: true,
  },
  DispatchCalendarDay: {
    merge: true,
  },
  EmployeeApprovalStatus: {
    merge: true,
  },
  Organization: {
    fields: {
      features: {
        // Short for options.mergeObjects(existing, incoming).
        merge: true,
      },
      block: {
        merge: true,
      },
    },
  },
};
const createCache = (options = {}) => {
  return new InMemoryCache({
    addTypename: true,
    ...options,
    typePolicies,
  });
};

interface ClientConfig extends Omit<ApolloClientOptions<unknown>, 'cache'> {
  middleware?: ApolloLink[];
  defaultOptions?: DefaultOptions;
  cache: InMemoryCache;
}

const createClient = (config: ClientConfig) => {
  const {cache, middleware = [], defaultOptions = {}, ...options} = config;

  return new ApolloClient({
    link: ApolloLink.from(middleware),
    cache,
    defaultOptions: merge({}, DEFAULT_OPTIONS, defaultOptions),
    ...options,
  });
};

export {
  createCache,
  createClient,
  // Middleware
  createAuthenticationMiddleware,
  createErrorMiddleware,
  createGraphQLMiddleware,
  createHeadersMiddleware,
  createRetryMiddleware,
  RestContext,
};
