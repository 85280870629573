// Libraries
import {
  ApolloClient as ApolloTypedClient,
  ApolloProvider as ApolloTypedProvider,
} from '@apollo/client';
import {MockedProvider} from '@apollo/client/testing';
import {ApolloClient} from 'apollo-client';
import {
  ApolloProvider,
  Mutation,
  Query,
  useApolloClient,
  useLazyQuery,
  useSubscription,
} from 'react-apollo';

// App
import {
  createAuthenticationMiddleware,
  createCache,
  createClient,
  createErrorMiddleware,
  createGraphQLMiddleware,
  createHeadersMiddleware,
  createRetryMiddleware,
  RestContext,
} from './apollo';
import {
  createAuthenticationMiddleware as createAuthenticationMiddlewareTyped,
  createCache as createCacheTyped,
  createClient as createClientTyped,
  createErrorMiddleware as createErrorMiddlewareTyped,
  createGraphQLMiddleware as createGraphQLMiddlewareTyped,
  createHeadersMiddleware as createHeadersMiddlewareTyped,
  createRetryMiddleware as createRetryMiddlewareTyped,
  RestContext as RestContextTyped,
} from './apolloTyped';
import {gql as gqlTyped} from './generated/gql';
import * as generated from './generated/graphql';
import gql from './graphql-tag';
import usePersistCache from './usePersistCache';
import {useApolloContextWithHeaders, useMutation, useQuery} from './wrappers';
import {useTypedMutation, useTypedQuery} from './wrappersTyped';

export {
  generated,
  // Apollo
  ApolloProvider,
  ApolloTypedProvider,
  Query,
  Mutation,
  MockedProvider,
  useQuery,
  useTypedQuery,
  useLazyQuery,
  useMutation,
  useTypedMutation,
  useSubscription,
  useApolloClient,
  // GraphQL
  gql,
  gqlTyped,
  // Client
  createCache,
  createCacheTyped,
  createClient,
  createClientTyped,
  // Hooks
  useApolloContextWithHeaders,
  usePersistCache,
  // Middleware
  createAuthenticationMiddleware,
  createAuthenticationMiddlewareTyped,
  createErrorMiddleware,
  createErrorMiddlewareTyped,
  createGraphQLMiddleware,
  createGraphQLMiddlewareTyped,
  createHeadersMiddleware,
  createHeadersMiddlewareTyped,
  createRetryMiddleware,
  createRetryMiddlewareTyped,
  RestContext,
  RestContextTyped,
};

gql.query = `
  __typename
`;

gql.errors = `
  errors {
    field
    message
  }
`;

export type ClientType = ApolloClient<unknown>;
export type ApolloTypedClientType = ApolloTypedClient<unknown>;
