// Libraries
import {throttle} from 'lodash';

// Supermove
import {useEffect, useRef} from '@supermove/hooks';
import {Environment} from '@supermove/sdk';

interface UseIdleTimerParams {
  onIdle?: () => void;
  onActive?: () => void;
  timeout?: number;
  events?: string[];
}

const useIdleTimer = ({onIdle, onActive, timeout, events}: UseIdleTimerParams) => {
  const eventsList = events || ['mousemove', 'keydown', 'touchstart', 'touchmove', 'scroll'];
  const timerMs = timeout || 1000 * 60 * 30;
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const wasIdle = useRef(false);

  const handleActivity = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      if (wasIdle.current && onActive) {
        if (Environment.getAppEnv() !== 'production') {
          console.log('[Idle Timer] Firing active callback');
        }
        onActive();
      }
      wasIdle.current = false;
    }

    timeoutRef.current = setTimeout(() => {
      if (onIdle) {
        if (Environment.getAppEnv() !== 'production') {
          console.log('[Idle Timer] Firing idle callback');
        }
        onIdle();
      }
      wasIdle.current = true;
    }, timerMs);
  };

  const resetTimer = throttle(handleActivity, timeout || 30 * 1000); // Throttled to reduce CPU usage

  useEffect(() => {
    for (const eventName of eventsList) {
      window.addEventListener(eventName, resetTimer);
    }
    resetTimer();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      for (const eventName of eventsList) {
        window.removeEventListener(eventName, resetTimer);
      }
    };
  }, [onIdle, onActive, timeout, events, resetTimer, eventsList]);
};

export default useIdleTimer;
