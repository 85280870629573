// Libraries
import {css} from 'styled-components';

import {ResponsiveType} from '@supermove/hooks';
import {Platform} from '@supermove/sdk';

// App
import colors from './colors';

// Utils
const getAndroidFontFamily = ({family, weight}: {family: string; weight: number}) => {
  switch (weight) {
    case 900:
      return `${family}-Black`;
    case 700:
      return `${family}-Heavy`;
    case 500:
      return `${family}-Medium`;
    case 300:
      return `${family}-Roman`;
    case 200:
      return `${family}-Book`;
    default:
      // Default to font-weight: 300
      return `${family}-Roman`;
  }
};

export const fontWeight = (weight: number, family = 'Avenir') => {
  return Platform.select({
    android: css`
      /* stylelint-disable font-family-no-missing-generic-family-keyword */
      font-family: ${getAndroidFontFamily({family, weight})};
    `,
    default: css`
      font-family: ${family};
      font-weight: ${weight};
    `,
  });
};

// Headings
const h1 = (props: any) => css`
  font-family: Avenir;
  font-size: 40px;
  line-height: 55px;
  ${fontWeight(300)}
  color: ${colors.gray.primary};
`;

const h2 = (props: any) => css`
  font-family: Avenir;
  font-size: 32px;
  line-height: 44px;
  ${fontWeight(300)}
  color: ${colors.gray.primary};
`;

const h3 = (props: any) => css`
  font-family: Avenir;
  font-size: 26px;
  line-height: 36px;
  ${fontWeight(300)}
  color: ${colors.gray.primary};
`;

const h4 = (props: any) => css`
  font-family: Avenir;
  font-size: 22px;
  line-height: 28px;
  ${fontWeight(300)}
  color: ${colors.gray.primary};
`;

const h5 = (props: any) => css`
  font-family: Avenir;
  font-size: 18px;
  line-height: 25px;
  ${fontWeight(300)}
  color: ${colors.gray.primary};
`;

const h6 = (props: any) => css`
  font-family: Avenir;
  font-size: 16px;
  line-height: 22px;
  ${fontWeight(300)}
  color: ${colors.gray.primary};
`;

const h7 = (props: any) => css`
  font-family: Avenir;
  font-size: 14px;
  line-height: 19px;
  ${fontWeight(300)}
  color: ${colors.gray.primary};
`;

const h8 = (props: any) => css`
  font-family: Avenir;
  font-size: 12px;
  line-height: 15px;
  ${fontWeight(300)}
  color: ${colors.gray.primary};
`;

export default {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7,
  h8,
};

const PageHeading = css`
  font-family: Avenir;
  color: ${colors.gray.primary};
  ${fontWeight(700)}
  font-size: 24px;
  line-height: 36px;
`;
const Heading1 = css`
  font-family: Avenir;
  color: ${colors.gray.primary};
  ${fontWeight(700)}
  font-size: 22px;
  line-height: 32px;
`;
const Heading2 = css`
  font-family: Avenir;
  color: ${colors.gray.primary};
  ${fontWeight(700)}
  font-size: 18px;
  line-height: 26px;
`;
const Subheading = css`
  font-family: Avenir;
  color: ${colors.gray.primary};
  ${fontWeight(700)}
  font-size: 16px;
  line-height: 24px;
`;
const Label = css`
  font-family: Avenir;
  color: ${colors.gray.primary};
  ${fontWeight(700)}
  font-size: 14px;
  line-height: 20px;
`;
const Body = css`
  font-family: Avenir;
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  font-size: 14px;
  line-height: 20px;
`;
const Link = css`
  font-family: Avenir;
  color: ${colors.blue.interactive};
  ${fontWeight(500)}
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  text-decoration-color: ${colors.blue.interactive};
  cursor: pointer;
`;
const Micro = css`
  font-family: Avenir;
  color: ${colors.gray.secondary};
  ${fontWeight(500)}
  font-size: 12px;
  line-height: 18px;
`;
const MicroLink = css`
  font-family: Avenir;
  color: ${colors.blue.interactive};
  ${fontWeight(500)}
  font-size: 12px;
  line-height: 18px;
  text-decoration-line: underline;
  text-decoration-color: ${colors.blue.interactive};
  cursor: pointer;
`;
const MicroLabel = css`
  font-family: Avenir;
  color: ${colors.gray.secondary};
  ${fontWeight(700)}
  font-size: 12px;
  line-height: 18px;
`;

const Mobile = {
  PageHeading: css`
    ${PageHeading}
    font-size: 26px;
    line-height: 40px;
  `,
  Heading1: PageHeading,
  Heading2: css`
    ${Heading1}
    font-size: 20px;
    line-height: 30px;
  `,
  Subheading: Heading2,
  Label: Subheading,
  Body: css`
    ${Body}
    font-size: 16px;
    line-height: 24px;
  `,
  Link: css`
    ${Link}
    font-size: 16px;
    line-height: 24px;
  `,
  Micro: css`
    ${Micro}
    font-size: 14px;
    line-height: 20px;
  `,
  MicroLabel: css`
    ${MicroLabel}
    font-size: 14px;
    line-height: 20px;
  `,
  MicroLink: css`
    ${MicroLink}
    font-size: 14px;
    line-height: 20px;
  `,
};

// TODO(jay): Responsive should be required to make sure it's present everywhere a responsive label is used
// Too much to do here, creating a separate task
type MaybeResponsiveProps = {responsive?: ResponsiveType | null};

const getIsMobile = (props: MaybeResponsiveProps) => {
  return props?.responsive?.mobile || props?.responsive?.tablet;
};

const Responsive = {
  PageHeading: css<MaybeResponsiveProps>`
    ${(props) => (getIsMobile(props) ? Mobile.PageHeading : PageHeading)}
  `,
  Heading1: css<MaybeResponsiveProps>`
    ${(props) => (getIsMobile(props) ? Mobile.Heading1 : Heading1)}
  `,
  Heading2: css<MaybeResponsiveProps>`
    ${(props) => (getIsMobile(props) ? Mobile.Heading2 : Heading2)}
  `,
  Subheading: css<MaybeResponsiveProps>`
    ${(props) => (getIsMobile(props) ? Mobile.Subheading : Subheading)}
  `,
  Label: css<MaybeResponsiveProps>`
    ${(props) => (getIsMobile(props) ? Mobile.Label : Label)}
  `,
  Body: css<MaybeResponsiveProps>`
    ${(props) => (getIsMobile(props) ? Mobile.Body : Body)}
  `,
  Link: css<MaybeResponsiveProps>`
    ${(props) => (getIsMobile(props) ? Mobile.Link : Link)}
  `,
  Micro: css<MaybeResponsiveProps>`
    ${(props) => (getIsMobile(props) ? Mobile.Micro : Micro)}
  `,
  MicroLink: css<MaybeResponsiveProps>`
    ${(props) => (getIsMobile(props) ? Mobile.MicroLink : MicroLink)}
  `,
  MicroLabel: css<MaybeResponsiveProps>`
    ${(props) => (getIsMobile(props) ? Mobile.MicroLabel : MicroLabel)}
  `,
};

export const Typography = {
  Mobile,
  Responsive,

  PageHeading,
  Heading1,
  Heading2,
  Subheading,
  Label,
  Body,
  Link,
  Micro,
  MicroLink,
  MicroLabel,

  // DEPRECATED TYPOGRAPHIES
  Heading3: css`
    font-family: Avenir;
    color: ${colors.gray.primary};
    ${fontWeight(700)}
    font-size: 28px;
    line-height: 36px;
  `,
  Heading4: css`
    font-family: Avenir;
    color: ${colors.gray.primary};
    ${fontWeight(700)}
    font-size: 24px;
    line-height: 32px;
  `,
  Heading5: css`
    font-family: Avenir;
    color: ${colors.gray.primary};
    ${fontWeight(700)}
    font-size: 20px;
    line-height: 28px;
  `,
  Heading6: css`
    font-family: Avenir;
    color: ${colors.gray.primary};
    ${fontWeight(700)}
    font-size: 18px;
    line-height: 28px;
  `,
  Body1: css`
    font-family: Avenir;
    color: ${colors.gray.primary};
    ${fontWeight(500)}
    font-size: 18px;
    line-height: 28px;
  `,
  Body2: css`
    font-family: Avenir;
    color: ${colors.gray.primary};
    ${fontWeight(500)}
    font-size: 16px;
    line-height: 24px;
  `,
  Body3: css`
    font-family: Avenir;
    color: ${colors.gray.primary};
    ${fontWeight(500)}
    font-size: 14px;
    line-height: 20px;
  `,
  Body4: css`
    font-family: Avenir;
    color: ${colors.gray.primary};
    ${fontWeight(500)}
    font-size: 12px;
    line-height: 16px;
  `,
  Body5: css`
    font-family: Avenir;
    color: ${colors.gray.primary};
    ${fontWeight(500)}
    font-size: 11px;
    line-height: 13px;
  `,
  Label1: css`
    font-family: Avenir;
    color: ${colors.gray.primary};
    ${fontWeight(700)}
    font-size: 16px;
    line-height: 24px;
  `,
  Label2: css`
    font-family: Avenir;
    color: ${colors.gray.primary};
    ${fontWeight(700)}
    font-size: 14px;
    line-height: 20px;
  `,
  Label3: css`
    font-family: Avenir;
    color: ${colors.gray.primary};
    ${fontWeight(700)}
    font-size: 12px;
    line-height: 16px;
  `,
  Label4: css`
    font-family: Avenir;
    color: ${colors.gray.primary};
    ${fontWeight(700)}
    font-size: 11px;
    line-height: 13px;
  `,
  Link1: css`
    font-family: Avenir;
    color: ${colors.blue.interactive};
    ${fontWeight(500)}
    font-size: 16px;
    line-height: 20px;
  `,
  Link2: css`
    font-family: Avenir;
    color: ${colors.blue.interactive};
    ${fontWeight(500)}
    font-size: 14px;
    line-height: 20px;
  `,
};
