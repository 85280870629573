// Libraries
import React from 'react';

// Supermove
import {ApolloProvider, ClientType} from '@supermove/graphql';

interface AppProps {
  client: ClientType;
  children: React.ReactNode;
}

const App = ({client, children}: AppProps = {} as AppProps) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export default App;
