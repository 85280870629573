// Libraries
import _ from 'lodash';
import React, {useState, useEffect} from 'react';
// @ts-expect-error upgrading this library should get the types
import ReactModal from 'react-modal';
import styled, {StyledComponent} from 'styled-components';

// Supermove
import Styled from '../Styled/Styled';

// Assets
import Animations from './animations';

const ScreenContainer = Styled.View`
  flex: 1;
`;

const POSITIONS = {
  LEFT: 'LEFT',
  TOP: 'TOP',
  RIGHT: 'RIGHT',
  BOTTOM: 'BOTTOM',
};

const ANIMATION_TIME = 250; // In milliseconds

const POSITION_ATTRIBUTES = {
  [POSITIONS.LEFT]: {
    style: {alignItems: 'flex-start'},
    openAnimation: Animations.slideInFromLeft,
    closeAnimation: Animations.slideOutToLeft,
  },
  [POSITIONS.TOP]: {
    style: {justifyContent: 'flex-start'},
    openAnimation: Animations.slideInFromTop,
    closeAnimation: Animations.slideOutToTop,
  },
  [POSITIONS.RIGHT]: {
    style: {alignItems: 'flex-end'},
    openAnimation: Animations.slideInFromRight,
    closeAnimation: Animations.slideOutToRight,
  },
  [POSITIONS.BOTTOM]: {
    style: {justifyContent: 'flex-end'},
    openAnimation: Animations.slideInFromBottom,
    closeAnimation: Animations.slideOutToBottom,
  },
};

const getStyle = ({
  position,
}: {
  position: keyof typeof POSITION_ATTRIBUTES;
}): React.CSSProperties => {
  // @ts-expect-error if this is changed to getting the props from POSITION_ATTRIBUTES this will probably be fixed
  return _.get(POSITION_ATTRIBUTES, `${position}.style`);
};

const getAnimation = ({
  isOpen,
  position,
}: {
  isOpen: boolean;
  position: keyof typeof POSITION_ATTRIBUTES | undefined;
}) => {
  if (isOpen) {
    return _.get(POSITION_ATTRIBUTES, `${position}.openAnimation`);
  }
  return _.get(POSITION_ATTRIBUTES, `${position}.closeAnimation`);
};

interface DrawerProps {
  isOpen?: boolean;
  onClose?: () => void;
  className?: string;
  children?: React.ReactNode;
  position?: string;
  shouldCloseOnClickOutside?: boolean;
  isAnimated?: boolean;
  contentContainerStyle?: object;
}

const Drawer = ({
  isOpen,
  onClose,
  className,
  children,
  position = POSITIONS.RIGHT,
  shouldCloseOnClickOutside = true,
  contentContainerStyle = {},
  isAnimated = true,
  ...props
}: DrawerProps) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  // Depending on the component hierarchy, clicks can easily propagate to the
  // drawer overlay and cause it to immediately close right after opening. To
  // prevent this we manually handle this flag to only allow outside clicks to
  // close the drawer after the drawer has finished opening.
  const [shouldCloseOnOverlayClick, setShouldCloseOnOverlayClick] = useState(
    isOpen && shouldCloseOnClickOutside,
  );
  useEffect(() => {
    setTimeout(() => setShouldCloseOnOverlayClick(isOpen && shouldCloseOnClickOutside), 0);
  }, [isOpen, shouldCloseOnOverlayClick, setShouldCloseOnOverlayClick, shouldCloseOnClickOutside]);

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      className={contentClassName}
      portalClassName={className}
      overlayClassName={overlayClassName}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      closeTimeoutMS={ANIMATION_TIME}
      {...props}
    >
      <ScreenContainer
        pointerEvents={'box-none'}
        style={{...getStyle({position}), ...contentContainerStyle}}
      >
        {children}
      </ScreenContainer>
    </ReactModal>
  );
};

type AnimatedDrawerType = StyledComponent<
  ({
    isOpen,
    onClose,
    className,
    children,
    position,
    shouldCloseOnClickOutside,
    contentContainerStyle,
    isAnimated,
    ...props
  }: DrawerProps) => JSX.Element,
  any
> & {
  POSITIONS: typeof POSITIONS;
};

const AnimatedDrawer: AnimatedDrawerType = styled(Drawer).attrs({
  suppressClassNameWarning: true,
})`
  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(74, 74, 74, 0.3);
    animation: ${({isAnimated, isOpen}) =>
        !isAnimated ? '' : isOpen ? Animations.fadeIn : Animations.fadeOut}
      ${ANIMATION_TIME}ms;
  }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    pointer-events: none;
    animation: ${
        // @ts-expect-error looks like styled components aren't set to take in animations
        ({isAnimated, isOpen, position}) => (!isAnimated ? '' : getAnimation({isOpen, position}))
      }
      ${ANIMATION_TIME}ms;
  }
  &__content * {
    pointer-events: all;
  }
` as AnimatedDrawerType;

AnimatedDrawer.POSITIONS = POSITIONS;

export default AnimatedDrawer;
