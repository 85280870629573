// Libraries
import {useState} from 'react';

// Supermove
import {useMountEffect} from '@supermove/hooks';

// Analytics
import {UseVitallyType, VitallyType} from './VitallyTypes';

// This object is global so it does not cause re-renders.
const Vitally: VitallyType = {
  state: {
    isInitialized: false,
    hasAccount: false,
    hasUser: false,
  },

  init: ({token, domain}) => {
    // If the token isn't supplied, do not initialize.
    if (!token) {
      return;
    }

    // @ts-expect-error this is defined by the Vitally plugin
    window.Vitally.init(token, `https://${domain}.api.vitally.io/analytics/v1`);
    Vitally.state.isInitialized = true;
  },

  setAccount: ({accountId}) => {
    if (!Vitally.state.isInitialized) {
      return;
    }
    if (!accountId) {
      return;
    }

    // @ts-expect-error this is defined by the Vitally plugin
    window.Vitally.account({
      accountId, // We use the salesforce ID as the accountId on Vitally.
      traits: {}, // Required to be sent in the request.
    });
    Vitally.state.hasAccount = true;
  },

  setUser: ({userId, accountId, fullName, email, role, organizationSlug}) => {
    if (!Vitally.state.isInitialized) {
      return;
    }
    if (!Vitally.state.hasAccount) {
      return;
    }
    if (!accountId || !userId) {
      return;
    }

    // @ts-expect-error this is defined by the Vitally plugin
    window.Vitally.user({
      accountId, // We use the salesforce ID as the accountId on Vitally.
      userId, // We use email as the userId on Vitally.
      traits: {
        name: fullName,
        email,
        role,
        organizationIdentifier: organizationSlug,
      },
    });
    Vitally.state.hasUser = true;
  },

  nps: ({productName}) => {
    if (!Vitally.state.isInitialized) {
      return;
    }
    if (!Vitally.state.hasUser) {
      return;
    }

    // @ts-expect-error this is defined by the Vitally plugin
    window.Vitally.nps('survey', {
      productName,
      delay: 1 * 1000, // 1 second
      npsQuestion: ({productName}: {productName: string}) => {
        return `👋 Quick question – how likely are you to recommend ${productName} to others?`;
      },
    });
  },

  // Analytics functions
  identify: ({fullName, email, role, organizationSlug, companySalesforceId}) => {
    // For Vitally, we only want to log super users if they are in bluxomemovers. Otherwise, we
    // should not log any activity.
    if (role === 'super' && organizationSlug !== 'bluxomemovers') {
      return;
    }

    Vitally.setAccount({
      accountId: companySalesforceId,
    });
    Vitally.setUser({
      accountId: companySalesforceId,
      userId: email,
      fullName,
      email,
      role,
      organizationSlug,
    });
  },
  track: ({event, data}) => {
    // This is a no-op, we don't use Vitally to track events right now.
  },
  reset: () => {
    // This is a no-op, we don't reset the user at all right now.
  },
};

const useVitally: UseVitallyType = ({token, domain}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useMountEffect(() => {
    // If the Vitally.js library has already been loaded, do not load again.
    // @ts-expect-error this setting comes from the Vitally plugin.
    if (window.Vitally) {
      return;
    }

    const script = document.createElement('script');
    script.id = 'vitally';
    script.src = `https://cdn.vitally.io/vitally.js/v1/vitally.js`;
    script.async = true;
    script.onload = () => {
      Vitally.init({token, domain});
      setIsLoaded(true);
    };

    // This allows the Vitally global to be defined earlier than the script so that any methods
    // called will be evaluated properly.
    // eslint-disable-next-line
    eval(
      '!function(n,t,i){for(var o=n[t]=n[t]||[],r=function(i){o[i]=o[i]||function(){for(var n=[],t=0;t<arguments.length;t++)n[t]=arguments[t];return o.push([i,n])}},a=0,u=["init","user","account","organization","track","nps"];a<u.length;a++){r(u[a])}}(window,"Vitally");',
    );

    // Add and remove the script from the DOM.
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  });

  return Vitally;
};

export default useVitally;
