// Libraries
import {useMountEffect, useState} from '@supermove/hooks';
import {CodePush} from '@supermove/sdk';

const DONE_STATUSES = [
  CodePush.SyncStatus.UP_TO_DATE,
  CodePush.SyncStatus.UPDATE_INSTALLED,
  CodePush.SyncStatus.UPDATE_IGNORED,
  CodePush.SyncStatus.UNKNOWN_ERROR,
];

type DONE_STATUSES_TYPE = (typeof DONE_STATUSES)[number];

const useCodePushUpdater = () => {
  const [isUpdating, setIsUpdating] = useState(true);

  const syncUpdate = async () => {
    const options = {
      installMode: CodePush.InstallMode.IMMEDIATE,
    };

    const onStatusChange = (status: DONE_STATUSES_TYPE) => {
      console.log(`[CodePush] Status ${status}`);

      // This callback runs right before the app is restarted. It should be able to close the modal
      // before the restart takes place.
      if (DONE_STATUSES.includes(status)) {
        setIsUpdating(false);
      }
    };

    console.log('[CodePush] Syncing update...');
    try {
      await CodePush.sync(options, onStatusChange);
    } catch (error) {
      console.log('[CodePush] Error:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  // Run the sync effect on mount.
  useMountEffect(() => {
    syncUpdate();
  });

  return {
    isUpdating,
  };
};

export default useCodePushUpdater;
