// Libraries
import React from 'react';

// Components
import AnalyticsContext, {
  AnalyticsContextType,
} from '@supermove/analytics/src/context/AnalyticsContext';

const Track = ({
  children,
}: {
  children: (track: AnalyticsContextType['track']) => React.ReactNode;
}) => {
  return (
    <AnalyticsContext.Consumer>
      {(analytics) => children(analytics.track)}
    </AnalyticsContext.Consumer>
  );
};

export default Track;
