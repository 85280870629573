// Libraries
import React from 'react';
import SwiperCore, {Pagination, Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

// Supermove
import {useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Components
import Space from '../Space';
import Styled from '../Styled';

SwiperCore.use([Pagination, Navigation]);

const SwiperContainer = Styled.View`
  justify-content: space-between;
`;

const PaginationContainer = Styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

const PaginationDot = Styled.View<{isActive: boolean}>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-horizontal: 6px;
  background-color: ${(props) => (props.isActive ? colors.orange.status : colors.gray.border)};
`;

interface SwiperComponent {
  slides: React.ReactNode[];
  activeDotColor?: string;
  onEnd?: () => void;
  swiperRef?: React.MutableRefObject<any>;
}

const SwiperComponent = ({
  slides = [],
  activeDotColor,
  onEnd = () => {},
  swiperRef,
}: SwiperComponent) => {
  const [activeDot, setActiveDot] = useState(0);

  return (
    <SwiperContainer>
      <Swiper
        slidesPerView={1}
        // @ts-expect-error library says this doesn't exist
        onSnapIndexChange={(swiper) => setActiveDot(swiper.visibleSlidesIndexes[0])}
        watchSlidesVisibility
        onReachEnd={onEnd}
        onSwiper={(swiper) => {
          if (swiperRef) {
            swiperRef.current = swiper;
          }
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>{slide}</SwiperSlide>
        ))}
      </Swiper>
      <Space height={24} />
      <PaginationContainer>
        {slides.map((slide, index) => (
          <PaginationDot
            // @ts-expect-error library says this doesn't exist
            activeDotColor={activeDotColor}
            isActive={activeDot === index}
            key={index}
          />
        ))}
      </PaginationContainer>
    </SwiperContainer>
  );
};

export default SwiperComponent;
