// Libraries
import _ from 'lodash';
import React from 'react';

import {Environment, ErrorHandler} from '@supermove/sdk';

interface ErrorViewProps {
  onError: (error: Error) => void;
  user?: any;
  extra?: Record<string, unknown>;
  tags?: Record<string, unknown>;
}

/**
 * This component catches RENDER errors only. For more information about what it
 * does and does not catch, see:
 * https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries
 */
class ErrorView extends React.Component<ErrorViewProps> {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    return {
      error,
    };
  }

  componentDidMount() {
    ErrorHandler.initialize({
      environment: Environment.getAppEnv(),
      sentryUrl: Environment.get('SENTRY_URL') as string,
      ignoreErrors: [
        // Triggered by Google Maps on IE on every page request.
        'loaderCB',

        // Happens on mobile safari (I think) when the user does not have cookies enabled.
        'The operation is insecure.',

        // Happens when the observations aren't able to happen in a single animation frame.
        // We can safely ignore this error.
        // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        'ResizeObserver loop limit exceeded',

        // Triggered by Chrome and is usually called when coming back to a tab and it is refreshing.
        'Failed to fetch',

        // Casued by react-native Android when a network request has issues.
        'Network request failed',
      ],
      tracesSampleRate: Environment.getTracesSampleRate(),
      release: Environment.getCommitHash(),
      enableSessionReplay: Environment.get('SENTRY_ENABLE_SESSION_REPLAY') as boolean,
    });

    ErrorHandler.setUser(this.props.user ?? {});
    ErrorHandler.setExtra(this.props.extra ?? {});
    ErrorHandler.setTags(this.props.tags ?? {});
  }

  componentDidUpdate(previousProps: ErrorViewProps) {
    if (!_.isEqual(this.props.user, previousProps.user)) {
      ErrorHandler.setUser(this.props.user ?? {});
    }

    if (!_.isEqual(this.props.extra, previousProps.extra)) {
      ErrorHandler.setExtra(this.props.extra ?? {});
    }

    if (!_.isEqual(this.props.tags, previousProps.tags)) {
      ErrorHandler.setTags(this.props.tags ?? {});
    }
  }

  // TODO(atsu): Can we prevent sending multiple events to Sentry if the onGraphQLError middleware
  //  has already reported this error? Consider using Sentry's fingerprint fields
  componentDidCatch(error: Error, errorInfo: any) {
    // Send the error via ErrorHandler, then handle within the app.
    ErrorHandler.handleError(error);
    this.props.onError(error);
  }

  render() {
    return this.state.error ? null : this.props.children;
  }
}

export default ErrorView;
