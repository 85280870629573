// Libraries
import Platform from '@react-x/platform';
import _ from 'lodash';
import {browserName, isAndroid, isIOS, isWindows, isMacOs} from 'react-device-detect';

// Supermove
import {uuid} from '@supermove/utils';

// Config
import Environment from '../Environment';

// Enums
import PlatformType from './enums/PlatformType';

const UNIQUE_ID = 'device_id';

const DeviceInfo = {
  PlatformType,

  getBrand: () => {
    return '';
  },

  getBrowser: () => {
    return _.toUpper(browserName);
  },

  getBuildId: async () => {
    return '';
  },

  getBuildNumber: () => {
    return Environment.get('APP_BUILD');
  },

  getCarrier: async () => {
    return '';
  },

  getDeviceId: () => {
    return '';
  },

  getDeviceName: async () => {
    return '';
  },

  getDeviceType: () => {
    return '';
  },

  getHardwareOs: () => {
    if (isAndroid) {
      return 'ANDROID';
    }
    if (isIOS) {
      return 'IOS';
    }
    if (isWindows) {
      return 'WINDOWS';
    }
    if (isMacOs) {
      return 'MACOS';
    }
    return 'UNKNOWN';
  },

  getIsIOS: () => isIOS,

  getManufacturer: async () => {
    return '';
  },

  getPlatform: () => {
    return Platform.select({
      android: 'ANDROID',
      ios: 'IOS',
      web: 'WEB',
    });
  },

  getSystemName: () => {
    return '';
  },

  getSystemVersion: () => {
    return '';
  },

  /**
   * Generate a unique id to store in storage. If there is already a uuid set,
   * then return the original one.
   */
  getUniqueId: () => {
    // NOTE(mark): This uses localStorage so that it remains synchronous. The Storage
    // module from react-x is async.
    const uniqueId = localStorage.getItem(UNIQUE_ID);
    if (uniqueId) {
      return uniqueId;
    }

    const newUniqueId = uuid();
    localStorage.setItem(UNIQUE_ID, newUniqueId);
    return newUniqueId;
  },

  getUserAgent: async () => {
    return '';
  },

  getVersion: () => {
    return Environment.get('APP_VERSION');
  },

  isEmulator: () => {
    return false;
  },

  sourceHostname: () => {
    return window.location.hostname;
  },
};

export default DeviceInfo;
