// Libraries
import React from 'react';

import {Form} from '@supermove/hooks';

import UnitInputParser, {UnitInputParserProps} from './UnitInputParser';

interface UnitInputProps extends UnitInputParserProps {
  disabled?: boolean;
  keyboardType?: string;
  component?: any;
  name: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: (event: any) => void;
  setFieldValue: Form['setFieldValue'];
  setFieldTouched: Form['setFieldTouched'];
  style?: React.CSSProperties;
  options?: Record<string, unknown>;
  unitString: string;
}

const UnitInput = ({
  disabled,
  keyboardType = 'phone-pad',
  component,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  setFieldValue,
  setFieldTouched,
  style,
  options,
  unitString,
  ...props
}: UnitInputProps) => (
  <UnitInputParser
    {...props}
    name={name}
    disabled={disabled}
    keyboardType={keyboardType}
    placeholder={placeholder}
    value={value}
    component={component}
    onChangeText={(value) => setFieldValue(name, value)}
    onBlur={(event) => {
      onBlur && onBlur(event);
      setFieldTouched(name, true);
    }}
    style={style}
    options={options}
    unitString={unitString}
  />
);

export default UnitInput;
