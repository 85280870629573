// Libraries
import React from 'react';

interface PercentInputParserProps extends Record<string, unknown> {
  component?: React.ElementType;
  value: string;
  onChangeText: (value: string) => void;
  onBlur?: (event: FocusEvent) => void;
  onAfterBlur: (event: FocusEvent) => void;
  options?: Record<string, unknown>;
}

class PercentInputParser extends React.Component<PercentInputParserProps> {
  getMatches = (string: string, regex: RegExp) => {
    return string.match(regex) || [];
  };

  formatValue = (value: string) => {
    if (value === '') {
      return '';
    }

    // Allow `-, %, .` for negative, percents, and decimals.
    const numerics = this.getMatches(value, /[-]?\d+[.\d+]?[%]?/g).join('');
    const alpha = this.getMatches(value, /[a-zA-Z]+/g)
      .join('')
      .toLowerCase();

    if (alpha.length > 0) {
      return '';
    }

    const float = parseFloat(numerics) || 0;

    return `${float}%`;
  };

  handleBlur = (event: FocusEvent) => {
    this.props.onChangeText(this.formatValue(this.props.value));
  };

  render() {
    const {
      component: InputComponent = 'input',
      value,
      onChangeText,
      onBlur,
      onAfterBlur,
      options = {},
      ...props
    } = this.props;

    return (
      <InputComponent
        {...props}
        value={value}
        onChangeText={onChangeText}
        onBlur={(event: FocusEvent) => {
          onBlur && onBlur(event);
          this.handleBlur(event);
          onAfterBlur(event);
        }}
      />
    );
  }
}

export default PercentInputParser;
