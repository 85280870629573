// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {Device} from '@supermove/sdk';
import {colors, Typography, GlobalStyle} from '@supermove/styles';

import Button from '@shared/design/components/Button';
// Components

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const Content = Styled.View<{desktop: boolean}>`
  flex: 1;
  padding-top: 40px;
  padding-horizontal: ${(props) => (props.desktop ? 100 : 50)}px;
`;

const Title = Styled.Text`
  ${Typography.Heading4}
  margin-top: 40px;
  color: ${colors.gray.primary};
  text-align: center;
`;

const Subtitle = Styled.Text`
  ${Typography.Body3}
  margin-top: 15px;
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-vertical: 20px;
`;

const MaintenancePage = () => {
  const responsive = useResponsive();

  return (
    <Container {...responsive}>
      <GlobalStyle />
      <Content {...responsive}>
        <Title>Scheduled Maintenance</Title>
        <Subtitle>
          {`Supermove is currently undergoing scheduled maintenance. For more information, please contact us at help@supermove.co.`}
        </Subtitle>
        <Actions>
          <Button
            color={colors.blue.interactive}
            onPress={() => Device.restartApp()}
            text={'Reload'}
          />
        </Actions>
      </Content>
    </Container>
  );
};

export default MaintenancePage;
