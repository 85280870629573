// Libraries
import _ from 'lodash';

const Duration = {
  /**
   * Given a number of minutes, returns
   * "X hr Y min" with support for negative minutes.
   */
  toDisplayTime: (
    minutes: number,
    {
      hoursLabel = ' hr',
      minutesLabel = ' min',
      alwaysShowMinutes,
      alwaysShowHours,
    }: {
      hoursLabel?: string;
      minutesLabel?: string;
      alwaysShowMinutes?: boolean;
      alwaysShowHours?: boolean;
    } = {},
  ) => {
    const hours = Math.floor(Math.abs(minutes) / 60);
    const remainingMinutes = Math.floor(Math.abs(minutes) % 60);

    if (hours === 0 && remainingMinutes === 0) {
      return `${alwaysShowHours ? `0${hoursLabel} ` : ''}0${minutesLabel}`;
    } else {
      const signString = Math.sign(minutes) < 0 ? '-' : '';
      const hoursString = hours === 0 && !alwaysShowHours ? '' : `${hours}${hoursLabel}`;
      const minutesString =
        remainingMinutes === 0 && !alwaysShowMinutes
          ? ''
          : `${Math.abs(remainingMinutes)}${minutesLabel}`;
      const spaceString = hoursString && minutesString ? ' ' : '';
      return `${signString}${hoursString}${spaceString}${minutesString}`;
    }
  },

  /**
   * Given a number of seconds, returns "Xm Ys"
   */
  getDisplayTimeFromSeconds: (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}m ${remainingSeconds}s`;
  },

  /**
   * Given a number of seconds, returns "mm:ss"
   */
  getDisplayTimeFromSecondsVideoFormat: (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    // @ts-expect-error Pad start is expecting string but we are giving number
    return `${_.padStart(minutes, 2, '0')}:${_.padStart(remainingSeconds, 2, '0')}`;
  },

  toHours: (minutes: number) => {
    if (!minutes) {
      return 0;
    }
    const hours = minutes / 60;
    return _.round(hours, 2);
  },

  toMinutes: (hours: number) => {
    if (!hours) {
      return 0;
    }
    return hours * 60;
  },
};

export default Duration;
