// Libraries
// @ts-expect-error when we upgrade to a new version it will come with types
import {NavigationContext} from '@react-navigation/core';
import _ from 'lodash';
import {useContext} from 'react';

import {NavigationState, NavigationType} from '../NavigationTypes';

const getParams = ({state}: {state: NavigationState}) => {
  const combiner: any = (params: Record<string, any>, route: NavigationState) => {
    const routes = _.get(route, 'routes', []);

    return {
      ...params,
      ...route.params,
      ..._.reduce(routes, combiner, {}),
    };
  };

  return _.reduce([state], combiner, {});
};

const useNavigation = () => {
  const navigator = useContext<NavigationType>(NavigationContext);
  const state = _.get(navigator, 'state', {}) as NavigationState;
  const params: Record<string, any> = getParams({state});

  return {
    navigator,
    params,
  };
};

export default useNavigation;
