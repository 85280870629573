// Libraries
import Platform from '@react-x/platform';

// Config
import DeviceInfo from '../DeviceInfo';
import Environment from '../Environment';

interface GetHostParams {
  appEnv: string;
  apiUrl?: string;
}

const Config = {
  getAPIHost: () => {
    // TODO(atsu): This is a quick hack to unblock Caylent team - need to actually sort out env
    //  vars / environment names as we move to AWS
    const apiUrlOverride = process.env.GATSBY_API_URL_OVERRIDE;
    if (apiUrlOverride) {
      return apiUrlOverride;
    }

    const appEnv = Environment.getAppEnv();
    const apiUrl = Environment.get('API_URL');

    switch (Platform.OS) {
      case 'android':
        return Config.getAndroidAPIHost({appEnv, apiUrl});
      case 'ios':
        return Config.getiOSAPIHost({appEnv, apiUrl});
      case 'web':
        return Config.getWebAPIHost({appEnv, apiUrl});
      default:
        return '';
    }
  },

  getAppSyncAPIHost: () => {
    return process.env.APPSYNC_API_HOST;
  },

  getStripeClientId: () => {
    return process.env.GATSBY_APP_ENV === 'production'
      ? 'ca_FKVrh94LoFUx8Mims4fwuA1Xlfh9AVhI'
      : 'ca_FKVrGJzsV2JV4jqauECO0gTqnp5W93rP';
  },

  getStripePublishableKey: () => {
    return process.env.GATSBY_APP_ENV === 'production'
      ? 'pk_live_q8Iv9dkAqFfN73p7GCmcZVEX00x4o9FyPk'
      : 'pk_test_k1xKb8hc51OMTDC5oBOaoYXE004vfHkOfA';
  },

  getNylasClientId: () => {
    switch (process.env.GATSBY_APP_ENV) {
      case 'production':
        return 'dc68cd46-8ed3-4cd7-8901-ef138e15f1df';
      case 'staging':
        return '78de451c-05cd-431b-8163-4228ef9af978';
      case 'development':
        return '4956b0bf-301a-47a4-9fa7-c37cdfce757e';
      default:
        // Localhost
        return 'c14c3384-809e-4560-b13f-848e708bdf0a';
    }
  },

  // --------------------------------------------------
  // Platform Specific
  // --------------------------------------------------
  getAndroidAPIHost: ({appEnv, apiUrl}: GetHostParams) => {
    switch (appEnv) {
      case 'development':
        // This is the IP address that the default android emulator users.
        // return 'http://10.0.2.2:5000'
        // This is the IP address that Genymotion uses.

        // NOTE(mark): DeviceInfo.sourceHostname() on android just returns localhost, you need to set up
        // the local adb tunnel using `yarn start:android` still.
        return DeviceInfo.isEmulator()
          ? 'http://10.0.3.2:5000'
          : `http://${DeviceInfo.sourceHostname()}:5000`;
      default:
        return apiUrl;
    }
  },

  getiOSAPIHost: ({appEnv, apiUrl}: GetHostParams) => {
    switch (appEnv) {
      case 'development':
        // Developing locally on an actual device should use the computer's IP address.
        return DeviceInfo.isEmulator() ? apiUrl : `http://${DeviceInfo.sourceHostname()}:5000`;
      default:
        return apiUrl;
    }
  },

  getWebAPIHost: ({appEnv, apiUrl}: GetHostParams) => {
    switch (appEnv) {
      case 'staging':
        return 'https://api-staging.supermove.co';
      case 'production':
        return 'https://api.supermove.co';
      default:
        return '';
    }
  },
};

export default Config;
