// Libraries
import _ from 'lodash';

// Supermove
import {Form, FormValues, MutationError} from '@supermove/hooks';

// // Server Error handling
const getFieldName = ({field}: {field: string}) => {
  const parts = field.split('.');
  const camelCaseParts = parts.map((part) => _.camelCase(part));
  return camelCaseParts.join('.');
};

const setFormServerErrors = async <T extends FormValues>({
  form,
  errors: serverErrors = [],
}: {
  form: Form<T>;
  errors: MutationError[];
}) => {
  // Submit the form to trigger `touched` to be set to true for all fields.
  await form.submitForm();

  // Clear existing errors from previous submissions.
  const errors = {};

  // This will override errors so the first one for a given field shows first.
  _.forEachRight(serverErrors, (serverError) => {
    _.set(errors, getFieldName(serverError), serverError.message);
  });

  // Update the errors object all in one go to trigger one re-render.
  form.setErrors(errors);

  // Indicate that we're no longer submitting.
  form.setSubmitting(false);
};

export default setFormServerErrors;
