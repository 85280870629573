import {ErrorResponse} from 'apollo-link-error';
import {ServerError, ServerParseError} from 'apollo-link-http-common';
import {print} from 'graphql/language';

import ErrorHandler from '../ErrorHandler';

type NetworkError = Error | HttpError;
type HttpError = ServerError | ServerParseError;
function isHttpError(error: NetworkError): error is HttpError {
  return (error as HttpError).statusCode !== undefined;
}
function isServerParseError(error: NetworkError): error is ServerParseError {
  return (error as ServerParseError).bodyText !== undefined;
}

function extraFor(
  response: ErrorResponse,
  error: Error | undefined,
  tags: Tags,
): Record<string, any> {
  return {
    'graphql_query': print(response.operation.query),
    'graphql_variables': response.operation.variables,
    'error_name': error?.name,
    'error_message': error?.message,
    'error_stack': error?.stack,
    'debug.datadog_trace_url': `https://app.datadoghq.com/apm/trace/${tags.trace_id}`,
    'debug.sentry_trace_url': `https://sentry.io/organizations/supermove/issues/?project=-1&query=trace_id:${tags.trace_id}`,
  };
}

interface Tags {
  /* eslint-disable camelcase */
  graphql_operation: string;
  error_kind: string;
  trace_id: string;
  [key: string]: string;
}

function onGraphQLError(response: ErrorResponse): void {
  const {networkError, graphQLErrors} = response;
  const operation = response.operation.operationName;
  const context = response.operation.getContext();
  const traceId = context.response?.headers?.get('x-supermove-trace-id');

  if (graphQLErrors !== undefined && !(typeof graphQLErrors.forEach === `function`)) {
    const tags = {
      graphql_operation: operation,
      error_kind: 'graphql',
      trace_id: traceId,
    };
    ErrorHandler.handleMessage('Unexpected GraphQL error response', {
      level: 'error',
      tags,
      extra: extraFor(response, undefined, tags),
    });
    console.error(`Unexpected GraphQL error response`, graphQLErrors);
  }

  // Optional chaining triggers no-unused-expressions in our version github.com/eslint/eslint/issues/12822
  // This can be removed if we upgrade eslint versions
  // eslint-disable-next-line no-unused-expressions
  graphQLErrors?.forEach?.((error) => {
    if (error.extensions?.is_captured_by_server) {
      console.log(
        `Skipping reporting of GraphQL error already captured by the server (Trace ID: ${traceId})`,
      );
      return;
    }

    const tags: Tags = {
      graphql_operation: operation,
      error_kind: 'graphql',
      trace_id: traceId,
    };
    if (error.path) {
      tags.graphql_path = error.path.join('.');
    }
    const message = `[GraphQL error]: ${operation} - ${error.message}`;

    ErrorHandler.handleMessage(message, {
      level: 'error',
      tags,
      extra: extraFor(response, error, tags),
    });
  });

  if (networkError) {
    const tags: Tags = {
      graphql_operation: operation,
      error_kind: 'network',
      trace_id: traceId,
    };
    let title = `Network Error`;
    let fingerprint = ['network-error'];

    if (isHttpError(networkError)) {
      title = `HTTP Error (${networkError.statusCode} ${networkError.response.statusText})`;
      fingerprint = ['http-error', `${networkError.statusCode}`];
      tags.error_kind = 'http';
      tags.http_status_code = `${networkError.statusCode}`;
    }

    if (isServerParseError(networkError) && networkError.bodyText) {
      console.error(networkError.bodyText);
    }

    const message = `
    ${title}
    ${operation}
    ${networkError.message}
    `;
    ErrorHandler.handleMessage(message, {
      level: 'error',
      tags,
      extra: extraFor(response, networkError, tags),
      fingerprint,
    });
  }

  return;
}

export default onGraphQLError;
