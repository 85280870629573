// Libraries
import PropTypes from 'prop-types';
import React from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

// Supermove
import {useDraggableInPortal} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {uuid} from '@supermove/utils';

// App
import Icon from '../Icon';
import Space from '../Space';
import Styled from '../Styled';

const ItemContainer = Styled.View<{index: number}>`
  flex-direction: row;
  align-items: center;
  flex: 1;
  zIndex: ${({index}) => 5000 - index};
`;

const DraggableIcon = () => {
  return <Icon color={colors.gray.secondary} size={Icon.Sizes.Large} source={Icon.GripVertical} />;
};

interface DragAndDropListProps {
  onReorder: (params: {fromIndex: number; toIndex: number}) => void;
  children: React.ReactNode;
  DraggableIconComponent?: React.ReactNode;
  isReordering?: boolean;
  spaceBetweenItems?: number;
  scrollRef?: React.RefObject<HTMLDivElement>;
  droppableStyle?: React.CSSProperties;
  itemContainerStyle?: React.CSSProperties;
  indexOfEdit?: number | null;
  isDisabled?: boolean;
  isDisabledWithVisibleIcons?: boolean;
  disabledIndexes?: number[];
}

const DragAndDropList = ({
  isReordering,
  onReorder,
  children,
  DraggableIconComponent,
  spaceBetweenItems,
  scrollRef,
  droppableStyle,
  itemContainerStyle,
  indexOfEdit,
  isDisabled,
  isDisabledWithVisibleIcons,
  disabledIndexes,
}: DragAndDropListProps) => {
  const renderDraggable = useDraggableInPortal();

  return (
    <DragDropContext
      onDragEnd={(result) => {
        if (!result.source || !result.destination) {
          return;
        }
        onReorder({fromIndex: result.source.index, toIndex: result.destination.index});
      }}
    >
      <Droppable droppableId={uuid()}>
        {(droppableProvided) => (
          <div
            ref={(element) => {
              droppableProvided.innerRef(element);
              if (scrollRef) {
                // @ts-expect-error we're not meant to assign to scrollRef
                scrollRef.current = element;
              }
            }}
            {...droppableProvided.droppableProps}
            style={{
              display: 'inline-block',
              overflowY: 'auto',
              ...droppableStyle,
            }}
          >
            {React.Children.map(children, (item, index) => {
              const isEditing = typeof indexOfEdit == 'number';
              const isEditingOrReordering = isEditing || isReordering;
              const isEditingAnotherItem = isEditing && indexOfEdit !== index;
              const isDisabledIndex = disabledIndexes?.includes(index);
              return (
                // @ts-expect-error item seems to be typed differently than it's used here
                <React.Fragment key={item?.key}>
                  <Draggable
                    isDragDisabled={
                      isDisabled ||
                      isEditingOrReordering ||
                      isDisabledWithVisibleIcons ||
                      isDisabledIndex
                    }
                    // @ts-expect-error item seems to be typed differently than it's used here
                    draggableId={item.key}
                    index={index}
                  >
                    {/* @ts-expect-error this type is complicated, should be fixed up later */}
                    {renderDraggable((draggableProvided) => (
                      <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                        <ItemContainer
                          index={index}
                          style={{
                            marginBottom: spaceBetweenItems,
                            ...itemContainerStyle,
                            ...(isEditingAnotherItem || isDisabledWithVisibleIcons
                              ? {opacity: 0.5}
                              : {}),
                          }}
                        >
                          {!isDisabled && !isDisabledIndex && (
                            <React.Fragment>
                              <div {...draggableProvided.dragHandleProps}>
                                {DraggableIconComponent}
                              </div>
                              <Space width={10} />
                            </React.Fragment>
                          )}
                          {item}
                        </ItemContainer>
                      </div>
                    ))}
                  </Draggable>
                </React.Fragment>
              );
            })}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DragAndDropList.DraggableIcon = DraggableIcon;

// --------------------------------------------------
// Props
// --------------------------------------------------
DragAndDropList.propTypes = {
  onReorder: PropTypes.func,
  // TODO (jay): Make this required when converting to TS, the required prop here is not compatible with React.ReactNode
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  DraggableIconComponent: PropTypes.element,
  isReordering: PropTypes.bool,
  spaceBetweenItems: PropTypes.number,
  scrollRef: PropTypes.object,
  droppableStyle: PropTypes.object,
  itemContainerStyle: PropTypes.object,
  indexOfEdit: PropTypes.number,
  isDisabled: PropTypes.bool,
  isDisabledWithVisibleIcons: PropTypes.bool,
};

DragAndDropList.defaultProps = {
  onReorder: () => {},
  DraggableIconComponent: <DraggableIcon />,
  children: undefined,
  isReordering: false,
  spaceBetweenItems: 0,
  scrollRef: null,
  droppableStyle: null,
  itemContainerStyle: null,
  indexOfEdit: null,
  isDisabled: false,
  isDisabledWithVisibleIcons: false,
};

export default DragAndDropList;
