// Libraries
import React from 'react';

// Supermove
import {colors, fontWeight} from '@supermove/styles';

// Components
import Icon, {IconSource} from '../../Icon';
import Space from '../../Space';
import Styled from '../../Styled';

const TabContainer = Styled.ButtonV2<{borderColor: string}>`
  padding-bottom: 10px;
  border-bottom-width: 4px;
  border-color: ${(props) => props.borderColor}
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Text = Styled.H6<{color: string}>`
  color: ${(props) => props.color};
  ${fontWeight(700)}
`;

const getBorderColor = ({isSelected, hasErrors}: {isSelected?: boolean; hasErrors?: boolean}) => {
  if (hasErrors) {
    return colors.red.warning;
  }

  if (isSelected) {
    return colors.blue.interactive;
  }

  return colors.gray.border;
};

const Tab = ({
  children,
  style,
  isSelected,
  hasErrors,
  onPress,
}: {
  children: React.ReactNode;
  style?: any;
  isSelected?: boolean;
  hasErrors?: boolean;
  onPress: () => void;
}) => {
  const borderColor = getBorderColor({isSelected, hasErrors});

  return (
    <TabContainer borderColor={borderColor} isSelected={isSelected} onPress={onPress} style={style}>
      {children}
    </TabContainer>
  );
};

const getTextColor = ({isSelected, hasErrors}: {isSelected?: boolean; hasErrors?: boolean}) => {
  if (hasErrors) {
    return colors.red.warning;
  }

  if (isSelected) {
    return colors.blue.interactive;
  }

  return colors.gray.primary;
};

const TabText = ({
  icon,
  style,
  isSelected,
  hasErrors,
  children,
}: {
  icon?: IconSource;
  style?: any;
  isSelected?: boolean;
  hasErrors?: boolean;
  children: React.ReactNode;
}) => {
  const textColor = getTextColor({isSelected, hasErrors});

  return (
    <React.Fragment>
      {icon && (
        <React.Fragment>
          <Icon source={icon} size={12} color={textColor} />
          <Space width={4} />
        </React.Fragment>
      )}
      <Text color={textColor} style={style}>
        {children}
      </Text>
    </React.Fragment>
  );
};

export interface TabBarTabProps {
  tab: {
    label: string;
    icon?: IconSource;
  };
  isSelected: boolean;
  handlePress: () => void;
}

const TabBarTab = ({tab, isSelected, handlePress}: TabBarTabProps) => {
  return (
    <Tab onPress={handlePress} isSelected={isSelected}>
      <TabText isSelected={isSelected} icon={tab.icon}>
        {tab.label}
      </TabText>
    </Tab>
  );
};

TabBarTab.Container = Tab;
TabBarTab.Text = TabText;

export default TabBarTab;
