// Libraries
import React from 'react';

import {Linking} from '@supermove/sdk';

// Components
import Styled from '../Styled';

const Touchable = Styled.ButtonV2`
`;

interface ExternalLinkProps {
  url: string;
  children?: React.ReactNode;
  style?: object;
}

const ExternalLink = ({url, children, style}: ExternalLinkProps) => (
  <Touchable onPress={() => Linking.openURL(url)} style={style}>
    {children}
  </Touchable>
);

export default ExternalLink;
