// Libraries
import _ from 'lodash';
// @ts-expect-error library is not typed
import numeral from 'numeral';

const getMatches = (string: string, regex: RegExp) => {
  return string.match(regex) || [];
};

const Percent = {
  display: (float: number) => {
    return Percent.toString(float);
  },
  fromFraction: ({
    numerator,
    denominator,
    round = 2,
  }: {
    numerator: number;
    denominator: number;
    round: number;
  }) => {
    return Percent.display(_.round(numerator / denominator, round));
  },
  toFloat: (percent: string | null) => {
    if (percent === null) {
      return 0;
    }
    const integer = parseFloat(percent) || 0;
    return integer / 100;
  },
  toString: (float: number) => {
    return numeral(float).format('0[.][000]%');
  },

  toForm: (float: number | string | null) => {
    if (float === null || float === undefined || float === '') {
      return '';
    }
    return Percent.display(float as number);
  },
  toMutation: (percent: string | null, isNullAllowed = false) => {
    if (isNullAllowed && (percent === '' || _.isNil(percent))) {
      return null;
    }
    return Percent.toFloat(percent);
  },

  // formatValue function taken from PercentInputParser
  formatValue: (value: string) => {
    // Allow `-, %, .` for negative, percents, and decimals.
    const numerics = getMatches(value, /[-]?\d+[.\d+]?[%]?/g).join('');
    const alpha = getMatches(value, /[a-zA-Z]+/g)
      .join('')
      .toLowerCase();

    if (alpha.length > 0) {
      return '';
    }

    const float = parseFloat(numerics) || 0;
    return `${float}%`;
  },
};

export default Percent;
