/* eslint-disable react/no-danger */
// @ts-expect-error library is not typed
import DOMPurify from 'dompurify';
import React, {useEffect, useRef, useState} from 'react';

const parseStylesAndBody = (rawHTML: string) => {
  let htmlStyles = '';
  let htmlBody = '';

  // Extract styles
  // Find start and end of style opening tag
  const styleTagStart = rawHTML.indexOf('<style');
  const cssStart = rawHTML.indexOf('>', styleTagStart) + 1;
  const cssEnd = rawHTML.indexOf('</style>');

  // We might not have a style tag
  if (styleTagStart !== -1 && cssStart !== 0 && cssEnd !== -1) {
    htmlStyles = `<style>${rawHTML.slice(cssStart, cssEnd)}</style>`;
  }

  // Extract html body
  // +6 for the length of <body>
  const bodyStart = rawHTML.indexOf('<body>') + 6;
  const bodyEnd = rawHTML.indexOf('</body>');

  // We might not have a body tag
  if (bodyStart !== 5 && bodyEnd !== -1) {
    htmlBody = rawHTML.slice(bodyStart, bodyEnd);
  } else {
    htmlBody = rawHTML;
  }

  return {htmlStyles, htmlBody};
};

const SanitizedHTML = ({rawHTML}: {rawHTML: string}) => {
  const {htmlStyles, htmlBody} = parseStylesAndBody(rawHTML);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeHeight, setIframeHeight] = useState<number | null | undefined>(null);

  // Sanitize the HTML and add a click handler to prevent links from opening
  // We need to recurse through all parent elements of an element to find one that may be a link,
  // since the click event may be triggered on a child element of the link that is a descendant of the <a> tag
  const sanitizedHtml = `
  <body>
    ${DOMPurify.sanitize(htmlBody)}
    <script>
      document.addEventListener('click', function(event) {
        let target = event.target;
        while (target && target.tagName !== 'A') {
          target = target.parentElement;
        }
        if (target && target.tagName === 'A') {
          event.preventDefault();
          window.open(target.href, '_blank');
        }
      }, true);
    </script>
  </body>
`;

  useEffect(() => {
    // Write the HTML into the iframe document
    const iframeDoc = iframeRef.current?.contentWindow?.document;
    if (iframeDoc) {
      iframeDoc.open();
      iframeDoc.write(htmlStyles, sanitizedHtml);
      iframeDoc.close();
    }

    // Handle setting iframe height and retry to ensure content has loaded
    let retryCount = 0;
    const intervalId = setInterval(() => {
      const newHeight = iframeRef.current?.contentWindow?.document.body.scrollHeight;
      if (iframeHeight !== newHeight) {
        setIframeHeight(newHeight);
      }
      // Stop retrying after 10 attempts (2 seconds)
      retryCount += 1;
      if (retryCount === 10) {
        clearInterval(intervalId);
      }
    }, 200);
    return () => clearInterval(intervalId);
  }, [htmlStyles, iframeHeight, rawHTML, sanitizedHtml]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        // @ts-expect-error ts says this doesn't exist
        paddingVertical: '24px',
        justifyContent: 'center',
      }}
    >
      <iframe
        ref={iframeRef}
        style={{
          height: `${iframeHeight}px`,
          width: '100%',
          border: 'none',
        }}
        width='100%'
        title='Content container'
        sandbox='allow-downloads allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox'
        scrolling='no'
      />
    </div>
  );
};

export default SanitizedHTML;
