// Libraries
import {useContext} from 'react';

// Analytics
import AnalyticsContext from '../context/AnalyticsContext';

const useAnalytics = () => {
  return useContext(AnalyticsContext);
};

export default useAnalytics;
