/**
 * useDebouncedCallback() hook, creates an instance of
 * _.debounce that persists across renders. Useful for
 * debouncing form inputs
 */

// Libraries
import _ from 'lodash';

// Supermove
import {useCallback, useRef} from '@supermove/hooks';

const useDebouncedCallback = <T extends (...args: any[]) => any>(
  callback: T,
  delay: number,
  options: _.DebounceSettings = {},
) => {
  const callbackRef = useRef<T>();
  callbackRef.current = callback;

  return useCallback(
    _.debounce((...args: Parameters<T>) => callbackRef.current?.(...args), delay, options),
    [],
  );
};

export default useDebouncedCallback;
