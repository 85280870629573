// Supermove
import {useIsFocused, useNavigation, useQuery} from '@supermove/hooks';

// TODO(mark): This hook keeps parity with the Page component and is used only
// for migrating to hooks. In the future, it should be deprecated in favor of
// using individual hooks on each page.
const usePage = ({
  fetchPolicy,
  getVariables,
  query,
  onFocus,
}: {
  fetchPolicy?:
    | 'cache-first'
    | 'cache-and-network'
    | 'network-only'
    | 'cache-only'
    | 'no-cache'
    | 'standby';
  getVariables?: (params: {navigator: any; params: any}) => any;
  query: any;
  onFocus?: (params: {refetch: () => void}) => void;
}) => {
  const {navigator, params} = useNavigation();
  const variables = getVariables ? getVariables({navigator, params}) : undefined;
  const {loading, data, refetch} = useQuery(query, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy,
    variables,
  });
  const isFocused = useIsFocused({
    onFocus: () => {
      if (onFocus) {
        onFocus({refetch});
      }
    },
  });

  return {
    isFocused,
    navigator,
    params,
    loading,
    data,
    refetch,
  };
};

export default usePage;
