// Libraries
import React from 'react';
import DatePicker from 'react-datepicker';
// @ts-expect-error package not typed
import {Portal} from 'react-overlays';

// App
import {createStyledDOMInput} from '@supermove/styles';

// Assets
import 'react-datepicker/dist/react-datepicker.css';
import './DateInput.css';
import {DateInputProps} from './dateInputTypes';

const StyledDatePicker = createStyledDOMInput(DatePicker);

/**
 * Normally react-popper will place the date picker popover in the same constraints
 * as the parent div (i.e. scrollable div).
 * isPortaled creates a container outside of the constrained parent container.
 * This way the date picker popover is able to be above other parent elements
 * without the use of z-index or overflow.
 */
const CalendarContainer = ({children}: {children: React.ReactElement}) => {
  const element = document.getElementById('calendar-portal');

  return <Portal container={element}>{children}</Portal>;
};

const Positions = {
  AUTO: 'auto',
  AUTO_LEFT: 'auto-left',
  AUTO_RIGHT: 'auto-right',
  BOTTOM: 'bottom',
  BOTTOM_END: 'bottom-end',
  BOTTOM_START: 'bottom-start',
  LEFT: 'left',
  LEFT_END: 'left-end',
  LEFT_START: 'left-start',
  RIGHT: 'right',
  RIGHT_END: 'right-end',
  RIGHT_START: 'right-start',
  TOP: 'top',
  TOP_END: 'top-end',
  TOP_START: 'top-start',
} as const;

const DateInput = ({
  disabled,
  required,
  isPastDisabled,
  isFutureDisabled,
  name,
  isPortaled = false,
  isEnabledFlip = false,
  offset = '0px, 0px',
  position = Positions.BOTTOM_START,
  placeholder,
  value,
  minDate,
  maxDate,
  setFieldValue,
  onChangeDate,
  style,
  customInput,
  excludeDates,
  autoFocus = false,
}: DateInputProps) => (
  <StyledDatePicker
    disabled={disabled}
    customInput={customInput}
    required={required}
    minDate={isPastDisabled ? new Date() : minDate}
    maxDate={isFutureDisabled ? new Date() : maxDate}
    placeholderText={placeholder}
    popperContainer={isPortaled ? CalendarContainer : null}
    popperPlacement={position}
    popperModifiers={{
      offset: {
        offset,
      },
      flip: {
        enabled: isEnabledFlip,
      },
      preventOverflow: {
        enabled: true,
        escapeWithReference: true,
      },
    }}
    selected={value === '' ? undefined : value} // HACK(mark): This is fixed in react-datepicker >1.8.0.
    onChange={(date: string | null) => {
      // `date` may come back null but we want an empty string instead of null.
      if (setFieldValue) {
        setFieldValue(name, date || '');
      }
      if (onChangeDate) {
        onChangeDate(date);
      }
    }}
    style={style}
    excludeDates={excludeDates}
    autoFocus={autoFocus}
  />
);

DateInput.Positions = Positions;

export default DateInput;
