// Libraries
import BrainfishWebWidget from '@brainfish-ai/web-widget';
import {ActionType} from '@brainfish-ai/widgets-common';

// Supermove
import {useMemo, useMountEffect} from '@supermove/hooks';

// Analytics
import {AnalyticsContextType} from '../../context/AnalyticsContext';
import useAnalytics from '../../hooks/useAnalytics';

import {BrainfishType, UseBrainfishWidgetArgs} from './BrainfishWidgetTypes';

const Brainfish: BrainfishType = {
  open: () => {
    BrainfishWebWidget.HelpWidget.open();
  },
  close: () => {
    BrainfishWebWidget.HelpWidget.close();
  },
  chat: ({query, answer, onOpenChat}) => {
    // Close the widget before opening the chat widget.
    BrainfishWebWidget.HelpWidget.close();

    // Open chat widget and prepopulate with question asked.
    if (onOpenChat) {
      onOpenChat({query, answer});
    }
  },
};

const initializeBrainfish = (analytics: AnalyticsContextType): BrainfishType => {
  // Since window is only available in the browser, we need to call the track function dynamically
  // whenever an event occurs.
  const trackEvent = ({event}: {event: string}) => {
    analytics.track({
      event,
      data: {
        Type: 'Brainfish Action',
        URL: window.location.pathname,
      },
    });
  };

  return {
    open: () => {
      trackEvent({event: 'Brainfish Widget Opened'});
      Brainfish.open();
    },
    close: () => {
      trackEvent({event: 'Brainfish Widget Closed'});
      Brainfish.close();
    },
    chat: ({query, answer, onOpenChat}) => {
      trackEvent({event: 'Brainfish Chat Opened'});
      Brainfish.chat({query, answer, onOpenChat});
    },
  };
};

const useBrainfishWidget = ({apiKey, onOpenChat}: UseBrainfishWidgetArgs) => {
  const analytics = useAnalytics();
  const brainfish = useMemo(() => initializeBrainfish(analytics), [analytics]);

  useMountEffect(() => {
    // Initialize the Brainfish module.
    BrainfishWebWidget.Widgets.init({
      widgetKey: apiKey as string,
      overrides: {
        nextBestActions: [
          // @ts-expect-error type says it needs icon, unsure if it actually does
          {
            type: ActionType.CALLBACK,
            label: 'Chat with Support',
            value: ({query, answer}: {query: string; answer: string}) => {
              brainfish.chat({query, answer, onOpenChat});
            },
          },
        ],
      },
    });
  });

  return brainfish;
};

export default useBrainfishWidget;
